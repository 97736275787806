import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
class CamelToSentencePipe {
  transform(text) {
    let output = '';
    text = text.replace(/[-_]/g, ' ');
    for (let i = 0; i < text.length; i++) {
      let char = text[i];
      if (i === 0) {
        char = char.toUpperCase();
      } else if (i > 0 && /[A-Z]/.test(char)) {
        if (!output.endsWith(' ')) {
          output += ' ';
        }
      }
      output += char;
    }
    return output;
  }
  static {
    this.ɵfac = function CamelToSentencePipe_Factory(t) {
      return new (t || CamelToSentencePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "camelToSentence",
      type: CamelToSentencePipe,
      pure: true
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CamelToSentencePipe, [{
    type: Pipe,
    args: [{
      name: 'camelToSentence'
    }]
  }], null, null);
})();
class CamelToSentencePipeModule {
  static {
    this.ɵfac = function CamelToSentencePipeModule_Factory(t) {
      return new (t || CamelToSentencePipeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CamelToSentencePipeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [CamelToSentencePipe],
      imports: [CommonModule]
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CamelToSentencePipeModule, [{
    type: NgModule,
    args: [{
      declarations: [CamelToSentencePipe],
      providers: [CamelToSentencePipe],
      imports: [CommonModule],
      exports: [CamelToSentencePipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CamelToSentencePipe, CamelToSentencePipeModule };
