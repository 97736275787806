import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ApiService,
  AuthenticationService,
  GoogleAnalyticsService,
} from '@wilson/wilsonng';
import { Wilson } from 'src/def/Wilson';
import Draft = Wilson.Draft;
import Roles = Wilson.Roles;
import User = Wilson.User;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private controller = 'user';

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService
  ) {}

  adminRoles = [
    Roles.wordalyzer_ContentApprover,
    Roles.wordalyzer_ContentEditor,
  ];
  customerRoles = [Roles.wordalyzer_Access, ...this.adminRoles];

  /**
   * Returns a boolean indicating whether the user can access the site
   * @param adminAccess check if the user has admin access
   */
  userHasAccess(adminAccess = false): boolean {
    const rolesToCheck = adminAccess ? this.adminRoles : this.customerRoles;

    let hasRequiredRole = false;
    rolesToCheck.forEach((role) => {
      if (this.authenticationService.userHasRole(role)) {
        hasRequiredRole = true;
      }
    });
    return hasRequiredRole;
  }

  canEditDraft(draft: Draft): boolean {
    if (!draft?.statusAudits?.length) {
      return true;
    }

    const ascendingDateSort = (a, b) => {
      const aTime = new Date(a.dateChanged).getTime();
      const bTime = new Date(b.dateChanged).getTime();
      return aTime - bTime;
    };

    const audits = [...draft.statusAudits].sort(ascendingDateSort);
    const isCreator =
      audits[0].changedById === this.authenticationService.currentUser.id;

    return (
      this.authenticationService.userHasRole(
        Roles.wordalyzer_ContentApprover
      ) || isCreator
    );
  }

  loginUser(): Observable<void> {
    // Create/update user in DB
    return this.apiService.get<void>(`${this.controller}/login-user`);
  }

  registerUserWithGoogleAnalytics(user: User): void {
    const id = user?.wilsonId;
    if (id) {
      this.googleAnalyticsService.setUserId(id);
    }
    this.googleAnalyticsService.initialize();
  }
}
