export namespace Wilson {
  export interface User extends LyzerEntity {
    wilsonId: string;
    authId: string;
    name: string;
    email: string;
    lastLogin: Date;
    roles: string[];
    initials: string;
  }

  export interface InitialLoad {
    googleAnalyticsKey: string;
    datadogTokens: { [key: string]: string };
  }

  export interface BulkUpdateDto<T> {
    propertyName: string;
    updates: { [key: string]: T };
    reasonForChange: string;
  }

  export enum Roles {
    prevention_Learning_Community = '00',
    intervention_Learning_Community = '01',
    intensive_Learning_Community = '02',
    fundations_Pre_K_Community = '13',
    ready_To_Rise = '69',
    web_Admin = '04',
    instructor = '05',
    administrator_Portal = '09',
    control_Panel_User = '16',
    content_Lead = '60',
    tech_Dev = '61',
    allow_Impersonate = '17',
    prevention_Virtual_Coaching = '14',
    intervention_Virtual_Coaching = '15',
    wordalyzer_Access = '11',
    wordalyzer_ContentApprover = '55',
    wordalyzer_ContentEditor = '56',
    subscription_Admin = '71',
    subscription_Customer_Portal = '72',
    subscription_Fundations_AllLevels = '81',
    subscription_Fundations_K = '73',
    subscription_Fundations_1 = '75',
    subscription_Fundations_2 = '77',
    subscription_Fundations_3 = '79',
    subscription_Interactivities_K = '74',
    subscription_Interactivities_1 = '76',
    subscription_Interactivities_2 = '78',
    subscription_Interactivities_3 = '80',
    subscription_Fundations_TeachersManual_K = '82',
    subscription_Fundations_TeachersManual_1 = '83',
    subscription_Fundations_TeachersManual_2 = '84',
    subscription_Fundations_TeachersManual_3 = '85',
  }

  export interface DraftResult {
    newDraft: Draft;
    isValid: boolean;
    validationResults: { [key: string]: string };
  }

  export enum WilsonProgram {
    AdministratorCommunity = 0,
    FundationsCommunityLevelPK = 1,
    FundationsCommunityLevelK = 2,
    FundationsCommunityLevel1 = 3,
    FundationsCommunityLevel2 = 4,
    FundationsCommunityLevel3 = 5,
    JustWordsCommunity = 6,
    ReadyToRiseCommunity = 7,
    WilsonReadingSystemCommunity = 8,
    WilsonHubLevelK = 9,
    WilsonHubLevel1 = 10,
    WilsonHubLevel2 = 11,
    WilsonHubLevel3 = 12,
    FundationsCommunityAllLevels = 13,
  }

  export interface Audit extends BaseEntity {
    itemId: string;
    itemType: string;
    auditType: string;
    loggedAt: Date;
    loggedById: string;
    loggedByName: string;
    previousEntity: BaseEntity;
    reasonForChange: string;
  }

  export interface BaseEntity extends DatabaseEntity<string> {}

  export interface DatabaseEntity<TId> {
    id: TId;
  }

  export interface Directory<T> {
    items: T[];
    count: number;
  }

  export interface Filter {
    column: string;
    value: any;
    filterType: FilterTypeDefinition;
    nestedFilters: Filter[];
  }

  export enum FilterTypeDefinition {
    Array = 0,
    Equals = 1,
    NotEquals = 2,
    StartsWith = 3,
    Contains = 4,
    NotContains = 5,
    EndsWith = 6,
    LessThan = 7,
    LessThanEqualTo = 8,
    GreaterThan = 9,
    GreaterThanEqualTo = 10,
  }

  export interface Sort {
    column: string;
    direction: SortDirectionDefinition;
    subArrayFilter: Filter;
  }

  export enum SortDirectionDefinition {
    Ascending = 1,
    Descending = -1,
  }

  export interface Parameters {
    filters: Filter[];
    dateRanges: { [key: string]: SearchDateRange };
    skip: number;
    take: number;
    sort: Sort;
  }

  export interface SearchDateRange {
    start: Date | null;
    end: Date | null;
  }

  export interface Draft extends LyzerEntity {
    entityId: string;
    action: DraftAction;
    status: DraftStatus;
    proposal: LyzerEntity;
    statusAudits: DraftStatusAudit[];
    reasonForChange: string;
    ownerUserId: string;
  }

  export enum DraftAction {
    Create = 0,
    Update = 1,
    Delete = 2,
  }

  export enum DraftStatus {
    New = 0,
    PendingReview = 1,
    NeedsEdits = 2,
    Rejected = 3,
    Approved = 4,
  }

  export interface DraftStatusAudit {
    changedById: string;
    changedByName: string;
    dateChanged: Date;
    newStatus: DraftStatus;
  }

  export interface LyzerEntity extends BaseEntity {}

  export interface MissingWord extends LyzerEntity {
    searchForCount: number;
    includeInReporting: boolean;
  }

  export interface Passage extends LyzerEntity {
    title: string;
    isReading: boolean;
    isSpelling: boolean;
    endOfStep: number | null;
    substepPhoneticDecodable: number | null;
    type: PassageType;
    wilsonReadingSystemVersion: WilsonReadingSystemVersion | null;
    text: string;
    isA: boolean;
    isB: boolean;
    isAcademic: boolean;
    isTargetedVocabulary: boolean;
    notes: string;
    lastModified: Audit;
    isPublished: boolean;
  }

  export enum PassageType {
    Passage = 0,
    Phrase = 1,
    Sentence = 2,
  }

  export enum WilsonReadingSystemVersion {
    ThirdEdition = 0,
    FourthEdition = 1,
    RevisedFourthEdition = 2,
  }

  export interface DirectoryWord extends LyzerEntity {
    text: string;
    isPublished: boolean;
    isA: boolean;
    isB: boolean;
    isTargetedVocabulary: boolean;
    isAcademic: boolean;
    isMSAcademic: boolean;
    isNonsense: boolean;
    wordFeatures: string;
    morphographicAnalysis: string;
    commonalityRanking: number | null;
    isRepeatWord: boolean;
    funHfTaught: number | null;
    funHfDecodable: number | null;
    funHfEncodable: number | null;
    funPhTaught: number | null;
    funPhDecodable: number | null;
    funPhEncodable: number | null;
    jwHfTaught: number | null;
    jwHfDecodable: number | null;
    jwHfEncodable: number | null;
    jwPhTaught: number | null;
    jwPhDecodable: number | null;
    jwPhEncodable: number | null;
    wrsHfTaught: number | null;
    wrsHfDecodable: number | null;
    wrsHfEncodable: number | null;
    wrsPhTaught: number | null;
    wrsPhDecodable: number | null;
    wrsPhEncodable: number | null;
  }

  export interface MorphographicAnalysis {
    analysis: string;
    baseElements: string[];
  }

  export interface Substep {
    program: WilsonProgram;
    taught: number | null;
    decodable: number | null;
    encodable: number | null;
    substepType: SubstepType;
  }

  export enum SubstepType {
    HiFrequency = 0,
    Phonetic = 1,
  }

  export interface Word extends LyzerEntity {
    isMSAcademic: boolean;
    isNonsense: boolean;
    vocabGradeLevel: number | null;
    commonalityRanking: number | null;
    fryRanking: number | null;
    programSubsteps: Substep[];
    wordFeatures: WordFeature[];
    morphographicAnalysis: MorphographicAnalysis;
    text: string;
    isA: boolean;
    isB: boolean;
    isTargetedVocabulary: boolean;
    isAcademic: boolean;
    notes: string;
    lastModified: Audit;
    isPublished: boolean;
  }

  export interface BoundedSubstep {
    program: WilsonProgram;
    substepPhoeneticTaughtMin: number;
    substepPhoeneticTaughtMax: number;
  }

  export interface WordFeature extends LyzerEntity {
    name: string;
    type: WordFeatureType;
    order: number;
    substepBounds: BoundedSubstep[];
  }

  export enum WordFeatureType {
    Graphemes_Phonemes = 0,
    Word_Elements = 1,
    Syllables = 2,
    Misc = 3,
  }

  export interface Analysis {
    words: Word[];
    directoryWords: DirectoryWord[];
    wordTotal: number;
    timeStamp: Date;
    analyzationTime: number;
    wordsNotDecodableSubtotal: SubtotalPercentage;
    wordsAnalyzedSubtotal: SubtotalPercentage;
    missingWordsSubtotal: SubtotalPercentage;
    unpublishedWordsSubtotal: SubtotalPercentage;
    aWordSubtotal: SubtotalPercentage;
    bWordSubtotal: SubtotalPercentage;
    abWordSubtotal: SubtotalPercentage;
    academicWordsSubtotal: SubtotalPercentage;
    msAcademicWordsSubtotal: SubtotalPercentage;
    targetWordsSubtotal: SubtotalPercentage;
    noSubstepsSubtotal: SubtotalPercentage;
    substepPercentageGroups: SubstepPercentageGroup[];
    featureTagSubtotals: FeatureTagSubtotalPercentage[];
  }

  export interface FeatureTagSubtotalPercentage {
    id: string;
    tag: string;
    subtotalPercentage: SubtotalPercentage;
  }

  export interface SubtotalPercentage {
    subtotal: number;
    percentage: number;
    includedWordIds: string[];
  }

  export interface SubstepPercentage {
    substep: number;
    subtotal: number;
    percentage: number;
    includedWordIds: string[];
  }

  export enum SubstepPercentageCategoryType {
    Decodable = 0,
    Taught = 1,
  }

  export interface SubstepPercentageGroup {
    categoryType: SubstepPercentageCategoryType;
    groupType: SubstepPercentageGroupType;
    substeps: SubstepPercentage[];
  }

  export enum SubstepPercentageGroupType {
    HiFrequency = 0,
    Phonetic = 1,
    Appropriate = 2,
  }


  export interface SavedDirectorySearch extends LyzerEntity {
    name: string;
    configuration: string;
    type: SavedDirectorySearchType;
    lastModified: Audit;
  }

  export enum SavedDirectorySearchType {
    Word = 0,
    Passage = 1,
  }

}
