import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService, RedirectService } from '@wilson/wilsonng';
import { MenuItem } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { Wilson } from 'src/def/Wilson';
import User = Wilson.User;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isAdmin = false;

  primaryTitle = 'Wordalyzer';
  secondaryTitle: string;
  pageLinks: HeaderLink[] = [];
  user: User;
  userInitials: string;
  userIsAdmin = false;
  menuItems: MenuItem[];

  constructor(
    private userService: UserService,
    private redirectService: RedirectService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.initializeFunnel();
    this.getUserInfo();
    this.initMenuItems();
  }

  getUserInfo() {
    this.user = this.authenticationService.currentUser;
    if (this.user) {
      this.userInitials = this.authenticationService.userInitials;
      this.userIsAdmin = this.userService.userHasAccess(true);
    } else {
      this.userIsAdmin = false;
    }
  }

  initializeFunnel(): void {
    // determine funnel and display accordingly
    if (this.isAdmin) {
      this.secondaryTitle = 'Admin';
      this.initPageLinks();
    }

    this.initMenuItems();
  }

  initMenuItems(): void {
    this.menuItems = [
      {
        label: 'Customer Portal',
        icon: 'pi pi-user',
        visible: this.isAdmin,
        routerLink: '/',
      },
      {
        label: 'Admin Portal',
        icon: 'pi pi-user',
        visible: !this.isAdmin && this.userIsAdmin,
        routerLink: '/admin',
      },
      { separator: true, visible: this.isAdmin || this.userIsAdmin },
      {
        label: 'Log out',
        icon: 'pi pi-sign-out',
        command: this.logout.bind(this),
      },
    ];
  }

  initPageLinks(): void {
    this.pageLinks = [
      {
        text: 'Dashboard',
        path: './',
      },
      {
        text: 'Quick Analysis',
        path: 'quick-analysis',
      },
      {
        text: 'Word Library',
        path: 'word-library',
      },
      {
        text: 'Text Library',
        path: 'text-library',
      },
    ];
  }

  logout(): void {
    const path = window.location.pathname;
    if (path !== '/') {
      this.redirectService.setRedirectCookie();
    }
    this.authenticationService.logout();
  }
}

interface HeaderLink {
  text: string;
  path: string;
}
