import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import * as i1 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
class GoogleAnalyticsService {
  constructor(router, route) {
    this.router = router;
    this.route = route;
    this.debugMode = false;
  }
  setKey(key) {
    this.key = key;
  }
  getKey() {
    return this.key;
  }
  setUserId(id) {
    this.userId = id;
  }
  getUserId() {
    return this.userId;
  }
  setDebugMode(useDebugMode) {
    this.debugMode = useDebugMode;
  }
  customEvent(type, category, label) {
    this.customGtag('event', type, {
      event_category: category,
      event_label: label
    });
  }
  initialize() {
    const scriptID = 'analytics';
    const preexisting = document.getElementById(scriptID);
    if (!preexisting) {
      if (!this.key) {
        return false;
      }
      const ga1 = document.createElement('script');
      ga1.id = scriptID;
      ga1.src = `https://www.googletagmanager.com/gtag/js?id=${this.key}`;
      ga1.type = 'text/javascript';
      ga1.async = true;
      document.getElementsByTagName('head')[0].appendChild(ga1);
      // initial page
      const ga2 = document.createElement('script');
      ga2.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments); }gtag('js', new Date());`;
      document.getElementsByTagName('head')[0].appendChild(ga2);
      this.initializePageHit();
    }
    return true;
  }
  customGtag(cmd, target, config) {
    if (window['gtag']) {
      // Some gtag calls don't require a 'target'
      !!target ? gtag(cmd, target, config) : gtag(cmd, config);
    }
  }
  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
  initializePageHit() {
    // subscribe to router events and send page views to Google Analytics
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (this.route.snapshot.data?.analyticsDisabled !== true) {
        // Set config options
        const configParams = {
          page_path: event.urlAfterRedirects
        };
        if (this.debugMode) {
          configParams['debug_mode'] = true;
        }
        if (this.userId) {
          configParams['user_id'] = this.userId;
        }
        this.customGtag('config', this.key, configParams);
        // Set wilson_id for GA4 User-ID tracking
        if (this.userId) {
          this.customGtag('set', 'user_properties', {
            wilson_id: this.userId
          });
        }
      }
    });
  }
  static {
    this.ɵfac = function GoogleAnalyticsService_Factory(t) {
      return new (t || GoogleAnalyticsService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoogleAnalyticsService,
      factory: GoogleAnalyticsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleAnalyticsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.Router
    }, {
      type: i1.ActivatedRoute
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { GoogleAnalyticsService };
