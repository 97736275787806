import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i1 from '@angular/common/http';
import * as i2 from '@wilson/wilsonng/src/lib/services/redirect';
class AuthenticationService {
  /** returns the currently logged in user
   * without needing to subscribe
   */
  get currentUser() {
    return this.userSubject.value;
  }
  get userInitials() {
    const user = this.userSubject.value;
    let initials = '';
    if (user) {
      initials = user.initials || this.getInitials(user.name);
    }
    return initials;
  }
  constructor(httpClient, redirectService) {
    this.httpClient = httpClient;
    this.redirectService = redirectService;
    this.controller = 'user';
    this.userSubject = new BehaviorSubject(null);
    this.user = this.userSubject.asObservable();
  }
  isLoggedIn() {
    return !!this.currentUser;
  }
  login(returnUrl = '/') {
    let loginUrl = `${this.controller}/login`;
    if (returnUrl !== '/') {
      loginUrl += `?returnUrl=${encodeURIComponent(returnUrl)}`;
    }
    this.getWindow().location.href = loginUrl;
  }
  logout() {
    this.getWindow().location.href = `${this.controller}/logout`;
  }
  getUser() {
    return this.httpClient.get(`${this.controller}`).pipe(tap(user => {
      if (user) {
        this.userSubject.next(user);
        return of(this.currentUser);
      }
      return of(void 0);
    }));
  }
  loginUser(loginUserEndpoint = 'login-user') {
    return this.httpClient.get(`${this.controller}/${loginUserEndpoint}`).pipe(tap(user => {
      if (user) {
        this.userSubject.next(user);
      } else {
        this.redirectService.setRedirectCookie();
        this.login();
      }
    }));
  }
  userHasRole(role) {
    return !!this.currentUser?.roles.includes(role);
  }
  setControllerName(controllerName) {
    this.controller = controllerName;
  }
  // Useful for mocking "window" in tests
  getWindow() {
    return window;
  }
  getInitials(name) {
    // if there isn't a space in the name,
    // they're using an email address as it, grab first 2 chars
    if (!name.includes(' ')) {
      return name.substring(0, 2);
    }
    // find the initials from the name
    return name.replace(/,/g, '').split(' ').map(word => word[0]).slice(0, 2).reverse().join('');
  }
  static {
    this.ɵfac = function AuthenticationService_Factory(t) {
      return new (t || AuthenticationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.RedirectService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthenticationService,
      factory: AuthenticationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthenticationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.HttpClient
    }, {
      type: i2.RedirectService
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AuthenticationService };
