import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const _c0 = function (a1, a2, a3) {
  return {
    "p-inputswitch p-component": true,
    "p-inputswitch-checked": a1,
    "p-disabled": a2,
    "p-focus": a3
  };
};
const INPUTSWITCH_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputSwitch),
  multi: true
};
/**
 * InputSwitch is used to select a boolean value.
 * @group Components
 */
class InputSwitch {
  cd;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex;
  /**
   * Identifier of the input element.
   * @group Props
   */
  inputId;
  /**
   * Name of the input element.
   * @group Props
   */
  name;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  disabled;
  /**
   * When present, it specifies that the component cannot be edited.
   * @group Props
   */
  readonly;
  /**
   * Value in checked state.
   * @group Props
   */
  trueValue = true;
  /**
   * Value in unchecked state.
   * @group Props
   */
  falseValue = false;
  /**
   * Used to define a string that autocomplete attribute the current element.
   * @group Props
   */
  ariaLabel;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Callback to invoke when the on value change.
   * @param {InputSwitchOnChangeEvent} event - Custom change event.
   * @group Emits
   */
  onChange = new EventEmitter();
  modelValue = false;
  focused = false;
  onModelChange = () => {};
  onModelTouched = () => {};
  constructor(cd) {
    this.cd = cd;
  }
  onClick(event, cb) {
    if (!this.disabled && !this.readonly) {
      event.preventDefault();
      this.toggle(event);
      cb.focus();
    }
  }
  onInputChange(event) {
    if (!this.readonly) {
      const inputChecked = event.target.checked;
      this.updateModel(event, inputChecked);
    }
  }
  toggle(event) {
    this.updateModel(event, !this.checked());
  }
  updateModel(event, value) {
    this.modelValue = value ? this.trueValue : this.falseValue;
    this.onModelChange(this.modelValue);
    this.onChange.emit({
      originalEvent: event,
      checked: this.modelValue
    });
  }
  onFocus(event) {
    this.focused = true;
  }
  onBlur(event) {
    this.focused = false;
    this.onModelTouched();
  }
  writeValue(value) {
    this.modelValue = value;
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  checked() {
    return this.modelValue === this.trueValue;
  }
  static ɵfac = function InputSwitch_Factory(t) {
    return new (t || InputSwitch)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: InputSwitch,
    selectors: [["p-inputSwitch"]],
    hostAttrs: [1, "p-element"],
    inputs: {
      style: "style",
      styleClass: "styleClass",
      tabindex: "tabindex",
      inputId: "inputId",
      name: "name",
      disabled: "disabled",
      readonly: "readonly",
      trueValue: "trueValue",
      falseValue: "falseValue",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy"
    },
    outputs: {
      onChange: "onChange"
    },
    features: [i0.ɵɵProvidersFeature([INPUTSWITCH_VALUE_ACCESSOR])],
    decls: 5,
    vars: 16,
    consts: [[3, "ngClass", "ngStyle", "click"], [1, "p-hidden-accessible"], ["type", "checkbox", "role", "switch", 3, "checked", "disabled", "change", "focus", "blur"], ["cb", ""], [1, "p-inputswitch-slider"]],
    template: function InputSwitch_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function InputSwitch_Template_div_click_0_listener($event) {
          i0.ɵɵrestoreView(_r1);
          const _r0 = i0.ɵɵreference(3);
          return i0.ɵɵresetView(ctx.onClick($event, _r0));
        });
        i0.ɵɵelementStart(1, "div", 1)(2, "input", 2, 3);
        i0.ɵɵlistener("change", function InputSwitch_Template_input_change_2_listener($event) {
          return ctx.onInputChange($event);
        })("focus", function InputSwitch_Template_input_focus_2_listener($event) {
          return ctx.onFocus($event);
        })("blur", function InputSwitch_Template_input_blur_2_listener($event) {
          return ctx.onBlur($event);
        });
        i0.ɵɵelementEnd()();
        i0.ɵɵelement(4, "span", 4);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(12, _c0, ctx.checked(), ctx.disabled, ctx.focused))("ngStyle", ctx.style);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("checked", ctx.checked())("disabled", ctx.disabled);
        i0.ɵɵattribute("aria-label", ctx.ariaLabel)("id", ctx.inputId)("name", ctx.name)("tabindex", ctx.tabindex)("aria-checked", ctx.checked())("aria-labelledby", ctx.ariaLabelledBy);
      }
    },
    dependencies: [i1.NgClass, i1.NgStyle],
    styles: [".p-inputswitch{position:relative;display:inline-block;-webkit-user-select:none;user-select:none}.p-inputswitch-slider{position:absolute;cursor:pointer;inset:0}.p-inputswitch-slider:before{position:absolute;content:\"\";top:50%}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputSwitch, [{
    type: Component,
    args: [{
      selector: 'p-inputSwitch',
      template: `
        <div [ngClass]="{ 'p-inputswitch p-component': true, 'p-inputswitch-checked': checked(), 'p-disabled': disabled, 'p-focus': focused }" [ngStyle]="style" [class]="styleClass" (click)="onClick($event, cb)">
            <div class="p-hidden-accessible">
                <input
                    #cb
                    type="checkbox"
                    [attr.aria-label]="ariaLabel"
                    [attr.id]="inputId"
                    [attr.name]="name"
                    [attr.tabindex]="tabindex"
                    [checked]="checked()"
                    (change)="onInputChange($event)"
                    (focus)="onFocus($event)"
                    (blur)="onBlur($event)"
                    [disabled]="disabled"
                    role="switch"
                    [attr.aria-checked]="checked()"
                    [attr.aria-labelledby]="ariaLabelledBy"
                />
            </div>
            <span class="p-inputswitch-slider"></span>
        </div>
    `,
      providers: [INPUTSWITCH_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-inputswitch{position:relative;display:inline-block;-webkit-user-select:none;user-select:none}.p-inputswitch-slider{position:absolute;cursor:pointer;inset:0}.p-inputswitch-slider:before{position:absolute;content:\"\";top:50%}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    trueValue: [{
      type: Input
    }],
    falseValue: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class InputSwitchModule {
  static ɵfac = function InputSwitchModule_Factory(t) {
    return new (t || InputSwitchModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: InputSwitchModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputSwitchModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [InputSwitch],
      declarations: [InputSwitch]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { INPUTSWITCH_VALUE_ACCESSOR, InputSwitch, InputSwitchModule };
