import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { forwardRef, Component, Inject, Input, ContentChildren, EventEmitter, PLATFORM_ID, ChangeDetectionStrategy, ViewEncapsulation, Output, ViewChild, NgModule } from '@angular/core';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { ChevronLeftIcon } from 'primeng/icons/chevronleft';
import { ChevronRightIcon } from 'primeng/icons/chevronright';
import { TimesIcon } from 'primeng/icons/times';
import * as i3 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import * as i2 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
function TabPanel_div_0_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TabPanel_div_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TabPanel_div_0_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.contentTemplate);
  }
}
function TabPanel_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵprojection(1);
    i0.ɵɵtemplate(2, TabPanel_div_0_ng_container_2_Template, 2, 1, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("hidden", !ctx_r0.selected);
    i0.ɵɵattribute("id", ctx_r0.id)("aria-hidden", !ctx_r0.selected)("aria-labelledby", ctx_r0.id + "-label");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.contentTemplate && (ctx_r0.cache ? ctx_r0.loaded : ctx_r0.selected));
  }
}
const _c0 = ["*"];
const _c1 = ["content"];
const _c2 = ["navbar"];
const _c3 = ["prevBtn"];
const _c4 = ["nextBtn"];
const _c5 = ["inkbar"];
function TabView_button_2_ChevronLeftIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronLeftIcon");
  }
}
function TabView_button_2_3_ng_template_0_Template(rf, ctx) {}
function TabView_button_2_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TabView_button_2_3_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TabView_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12, 13);
    i0.ɵɵlistener("click", function TabView_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.navBackward());
    });
    i0.ɵɵtemplate(2, TabView_button_2_ChevronLeftIcon_2_Template, 1, 0, "ChevronLeftIcon", 14);
    i0.ɵɵtemplate(3, TabView_button_2_3_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r0.previousIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.previousIconTemplate);
  }
}
function TabView_ng_template_7_li_0_ng_container_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 24);
  }
  if (rf & 2) {
    const tab_r12 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", tab_r12.leftIcon);
  }
}
function TabView_ng_template_7_li_0_ng_container_2_span_2_1_ng_template_0_Template(rf, ctx) {}
function TabView_ng_template_7_li_0_ng_container_2_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TabView_ng_template_7_li_0_ng_container_2_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TabView_ng_template_7_li_0_ng_container_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 25);
    i0.ɵɵtemplate(1, TabView_ng_template_7_li_0_ng_container_2_span_2_1_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const tab_r12 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", tab_r12.leftIconTemplate);
  }
}
function TabView_ng_template_7_li_0_ng_container_2_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 26);
  }
  if (rf & 2) {
    const tab_r12 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", tab_r12.rightIcon);
  }
}
function TabView_ng_template_7_li_0_ng_container_2_span_6_1_ng_template_0_Template(rf, ctx) {}
function TabView_ng_template_7_li_0_ng_container_2_span_6_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TabView_ng_template_7_li_0_ng_container_2_span_6_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TabView_ng_template_7_li_0_ng_container_2_span_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 27);
    i0.ɵɵtemplate(1, TabView_ng_template_7_li_0_ng_container_2_span_6_1_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const tab_r12 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", tab_r12.rightIconTemplate);
  }
}
function TabView_ng_template_7_li_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TabView_ng_template_7_li_0_ng_container_2_span_1_Template, 1, 1, "span", 19);
    i0.ɵɵtemplate(2, TabView_ng_template_7_li_0_ng_container_2_span_2_Template, 2, 1, "span", 20);
    i0.ɵɵelementStart(3, "span", 21);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, TabView_ng_template_7_li_0_ng_container_2_span_5_Template, 1, 1, "span", 22);
    i0.ɵɵtemplate(6, TabView_ng_template_7_li_0_ng_container_2_span_6_Template, 2, 1, "span", 23);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const tab_r12 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", tab_r12.leftIcon && !tab_r12.leftIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", tab_r12.leftIconTemplate);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(tab_r12.header);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", tab_r12.rightIcon && !tab_r12.rightIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", tab_r12.rightIconTemplate);
  }
}
function TabView_ng_template_7_li_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TabView_ng_template_7_li_0_ng_container_4_TimesIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r35 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "TimesIcon", 30);
    i0.ɵɵlistener("click", function TabView_ng_template_7_li_0_ng_container_4_TimesIcon_1_Template_TimesIcon_click_0_listener($event) {
      i0.ɵɵrestoreView(_r35);
      const tab_r12 = i0.ɵɵnextContext(3).$implicit;
      const ctx_r33 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r33.close($event, tab_r12));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-tabview-close");
  }
}
function TabView_ng_template_7_li_0_ng_container_4_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 31);
  }
}
function TabView_ng_template_7_li_0_ng_container_4_3_ng_template_0_Template(rf, ctx) {}
function TabView_ng_template_7_li_0_ng_container_4_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TabView_ng_template_7_li_0_ng_container_4_3_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TabView_ng_template_7_li_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TabView_ng_template_7_li_0_ng_container_4_TimesIcon_1_Template, 1, 1, "TimesIcon", 28);
    i0.ɵɵtemplate(2, TabView_ng_template_7_li_0_ng_container_4_span_2_Template, 1, 0, "span", 29);
    i0.ɵɵtemplate(3, TabView_ng_template_7_li_0_ng_container_4_3_Template, 1, 0, null, 15);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const tab_r12 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r16 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !tab_r12.closeIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r16.p - ctx_r16.tabview - ctx_r16.close);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", tab_r12.closeIconTemplate);
  }
}
const _c6 = function (a0, a1) {
  return {
    "p-highlight": a0,
    "p-disabled": a1
  };
};
function TabView_ng_template_7_li_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r40 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 17)(1, "a", 18);
    i0.ɵɵlistener("click", function TabView_ng_template_7_li_0_Template_a_click_1_listener($event) {
      i0.ɵɵrestoreView(_r40);
      const tab_r12 = i0.ɵɵnextContext().$implicit;
      const ctx_r38 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r38.open($event, tab_r12));
    })("keydown.enter", function TabView_ng_template_7_li_0_Template_a_keydown_enter_1_listener($event) {
      i0.ɵɵrestoreView(_r40);
      const tab_r12 = i0.ɵɵnextContext().$implicit;
      const ctx_r41 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r41.open($event, tab_r12));
    });
    i0.ɵɵtemplate(2, TabView_ng_template_7_li_0_ng_container_2_Template, 7, 5, "ng-container", 14);
    i0.ɵɵtemplate(3, TabView_ng_template_7_li_0_ng_container_3_Template, 1, 0, "ng-container", 15);
    i0.ɵɵtemplate(4, TabView_ng_template_7_li_0_ng_container_4_Template, 4, 3, "ng-container", 14);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const tab_r12 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵclassMap(tab_r12.headerStyleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(16, _c6, tab_r12.selected, tab_r12.disabled))("ngStyle", tab_r12.headerStyle);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("pTooltip", tab_r12.tooltip)("tooltipPosition", tab_r12.tooltipPosition)("positionStyle", tab_r12.tooltipPositionStyle)("tooltipStyleClass", tab_r12.tooltipStyleClass);
    i0.ɵɵattribute("id", tab_r12.id + "-label")("aria-selected", tab_r12.selected)("aria-controls", tab_r12.id)("aria-selected", tab_r12.selected)("tabindex", tab_r12.disabled ? null : "0");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !tab_r12.headerTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", tab_r12.headerTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", tab_r12.closable);
  }
}
function TabView_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TabView_ng_template_7_li_0_Template, 5, 19, "li", 16);
  }
  if (rf & 2) {
    const tab_r12 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", !tab_r12.closed);
  }
}
function TabView_button_10_ChevronRightIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronRightIcon");
  }
}
function TabView_button_10_3_ng_template_0_Template(rf, ctx) {}
function TabView_button_10_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TabView_button_10_3_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TabView_button_10_Template(rf, ctx) {
  if (rf & 1) {
    const _r49 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 32, 33);
    i0.ɵɵlistener("click", function TabView_button_10_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r49);
      const ctx_r48 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r48.navForward());
    });
    i0.ɵɵtemplate(2, TabView_button_10_ChevronRightIcon_2_Template, 1, 0, "ChevronRightIcon", 14);
    i0.ɵɵtemplate(3, TabView_button_10_3_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r5.nextIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r5.nextIconTemplate);
  }
}
const _c7 = function (a1) {
  return {
    "p-tabview p-component": true,
    "p-tabview-scrollable": a1
  };
};
let idx = 0;
/**
 * TabPanel is a helper component for TabView component.
 * @group Components
 */
class TabPanel {
  viewContainer;
  cd;
  /**
   * Defines if tab can be removed.
   * @group Props
   */
  closable = false;
  /**
   * Inline style of the tab header.
   * @group Props
   */
  headerStyle;
  /**
   * Style class of the tab header.
   * @group Props
   */
  headerStyleClass;
  /**
   * Whether a lazy loaded panel should avoid getting loaded again on reselection.
   * @group Props
   */
  cache = true;
  /**
   * Advisory information to display in a tooltip on hover.
   * @group Props
   */
  tooltip;
  /**
   * Position of the tooltip.
   * @group Props
   */
  tooltipPosition = 'top';
  /**
   * Type of CSS position.
   * @group Props
   */
  tooltipPositionStyle = 'absolute';
  /**
   * Style class of the tooltip.
   * @group Props
   */
  tooltipStyleClass;
  /**
   * Defines if tab is active.
   * @defaultValue false
   * @group Props
   */
  get selected() {
    return !!this._selected;
  }
  set selected(val) {
    this._selected = val;
    if (!this.loaded) {
      this.cd.detectChanges();
    }
    if (val) this.loaded = true;
  }
  /**
   * When true, tab cannot be activated.
   * @defaultValue false
   * @group Props
   */
  get disabled() {
    return !!this._disabled;
  }
  set disabled(disabled) {
    this._disabled = disabled;
    this.tabView.cd.markForCheck();
  }
  /**
   * Title of the tabPanel.
   * @group Props
   */
  get header() {
    return this._header;
  }
  set header(header) {
    this._header = header;
    // We have to wait for the rendering and then retrieve the actual size element from the DOM.
    // in future `Promise.resolve` can be changed to `queueMicrotask` (if ie11 support will be dropped)
    Promise.resolve().then(() => {
      this.tabView.updateInkBar();
      this.tabView.cd.markForCheck();
    });
  }
  /**
   * Left icon of the tabPanel.
   * @group Props
   * @deprecated since v15.4.2, use `lefticon` template instead.
   */
  get leftIcon() {
    return this._leftIcon;
  }
  set leftIcon(leftIcon) {
    this._leftIcon = leftIcon;
    this.tabView.cd.markForCheck();
  }
  /**
   * Left icon of the tabPanel.
   * @group Props
   * @deprecated since v15.4.2, use `righticon` template instead.
   */
  get rightIcon() {
    return this._rightIcon;
  }
  set rightIcon(rightIcon) {
    this._rightIcon = rightIcon;
    this.tabView.cd.markForCheck();
  }
  templates;
  closed = false;
  view = null;
  _selected;
  _disabled;
  _header;
  _leftIcon;
  _rightIcon = undefined;
  loaded = false;
  id = `p-tabpanel-${idx++}`;
  contentTemplate;
  headerTemplate;
  leftIconTemplate;
  rightIconTemplate;
  closeIconTemplate;
  tabView;
  constructor(tabView, viewContainer, cd) {
    this.viewContainer = viewContainer;
    this.cd = cd;
    this.tabView = tabView;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'content':
          this.contentTemplate = item.template;
          break;
        case 'righticon':
          this.rightIconTemplate = item.template;
          break;
        case 'lefticon':
          this.leftIconTemplate = item.template;
          break;
        case 'closeicon':
          this.closeIconTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }
  ngOnDestroy() {
    this.view = null;
  }
  static ɵfac = function TabPanel_Factory(t) {
    return new (t || TabPanel)(i0.ɵɵdirectiveInject(forwardRef(() => TabView)), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: TabPanel,
    selectors: [["p-tabPanel"]],
    contentQueries: function TabPanel_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      closable: "closable",
      headerStyle: "headerStyle",
      headerStyleClass: "headerStyleClass",
      cache: "cache",
      tooltip: "tooltip",
      tooltipPosition: "tooltipPosition",
      tooltipPositionStyle: "tooltipPositionStyle",
      tooltipStyleClass: "tooltipStyleClass",
      selected: "selected",
      disabled: "disabled",
      header: "header",
      leftIcon: "leftIcon",
      rightIcon: "rightIcon"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 1,
    consts: [["class", "p-tabview-panel", "role", "tabpanel", 3, "hidden", 4, "ngIf"], ["role", "tabpanel", 1, "p-tabview-panel", 3, "hidden"], [4, "ngIf"], [4, "ngTemplateOutlet"]],
    template: function TabPanel_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, TabPanel_div_0_Template, 3, 5, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", !ctx.closed);
      }
    },
    dependencies: [i1.NgIf, i1.NgTemplateOutlet],
    encapsulation: 2
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabPanel, [{
    type: Component,
    args: [{
      selector: 'p-tabPanel',
      template: `
        <div [attr.id]="id" class="p-tabview-panel" [hidden]="!selected" role="tabpanel" [attr.aria-hidden]="!selected" [attr.aria-labelledby]="id + '-label'" *ngIf="!closed">
            <ng-content></ng-content>
            <ng-container *ngIf="contentTemplate && (cache ? loaded : selected)">
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
            </ng-container>
        </div>
    `,
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: TabView,
      decorators: [{
        type: Inject,
        args: [forwardRef(() => TabView)]
      }]
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    closable: [{
      type: Input
    }],
    headerStyle: [{
      type: Input
    }],
    headerStyleClass: [{
      type: Input
    }],
    cache: [{
      type: Input
    }],
    tooltip: [{
      type: Input
    }],
    tooltipPosition: [{
      type: Input
    }],
    tooltipPositionStyle: [{
      type: Input
    }],
    tooltipStyleClass: [{
      type: Input
    }],
    selected: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    header: [{
      type: Input
    }],
    leftIcon: [{
      type: Input
    }],
    rightIcon: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
/**
 * TabView is a container component to group content with tabs.
 * @group Components
 */
class TabView {
  platformId;
  el;
  cd;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Whether tab close is controlled at onClose event or not.
   * @defaultValue false
   * @group Props
   */
  controlClose;
  /**
   * When enabled displays buttons at each side of the tab headers to scroll the tab list.
   * @defaultValue false
   * @group Props
   */
  scrollable;
  /**
   * Index of the active tab to change selected tab programmatically.
   * @group Props
   */
  get activeIndex() {
    return this._activeIndex;
  }
  set activeIndex(val) {
    this._activeIndex = val;
    if (this.preventActiveIndexPropagation) {
      this.preventActiveIndexPropagation = false;
      return;
    }
    if (this.tabs && this.tabs.length && this._activeIndex != null && this.tabs.length > this._activeIndex) {
      this.findSelectedTab().selected = false;
      this.tabs[this._activeIndex].selected = true;
      this.tabChanged = true;
      this.updateScrollBar(val);
    }
  }
  /**
   * Callback to invoke on tab change.
   * @param {TabViewChangeEvent} event - Custom tab change event
   * @group Emits
   */
  onChange = new EventEmitter();
  /**
   * Callback to invoke on tab close.
   * @param {TabViewCloseEvent} event - Custom tab close event
   * @group Emits
   */
  onClose = new EventEmitter();
  /**
   * Callback to invoke on the active tab change.
   * @param {number} index - New active index
   * @group Emits
   */
  activeIndexChange = new EventEmitter();
  content;
  navbar;
  prevBtn;
  nextBtn;
  inkbar;
  tabPanels;
  templates;
  initialized;
  tabs;
  _activeIndex;
  preventActiveIndexPropagation;
  tabChanged;
  backwardIsDisabled = true;
  forwardIsDisabled = false;
  tabChangesSubscription;
  nextIconTemplate;
  previousIconTemplate;
  constructor(platformId, el, cd) {
    this.platformId = platformId;
    this.el = el;
    this.cd = cd;
  }
  ngAfterContentInit() {
    this.initTabs();
    this.tabChangesSubscription = this.tabPanels.changes.subscribe(_ => {
      this.initTabs();
    });
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'previousicon':
          this.previousIconTemplate = item.template;
          break;
        case 'nexticon':
          this.nextIconTemplate = item.template;
          break;
      }
    });
  }
  ngAfterViewChecked() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.tabChanged) {
        this.updateInkBar();
        this.tabChanged = false;
      }
    }
  }
  ngOnDestroy() {
    if (this.tabChangesSubscription) {
      this.tabChangesSubscription.unsubscribe();
    }
  }
  initTabs() {
    this.tabs = this.tabPanels.toArray();
    let selectedTab = this.findSelectedTab();
    if (!selectedTab && this.tabs.length) {
      if (this.activeIndex != null && this.tabs.length > this.activeIndex) this.tabs[this.activeIndex].selected = true;else this.tabs[0].selected = true;
      this.tabChanged = true;
    }
    this.cd.markForCheck();
  }
  open(event, tab) {
    if (tab.disabled) {
      if (event) {
        event.preventDefault();
      }
      return;
    }
    if (!tab.selected) {
      let selectedTab = this.findSelectedTab();
      if (selectedTab) {
        selectedTab.selected = false;
      }
      this.tabChanged = true;
      tab.selected = true;
      let selectedTabIndex = this.findTabIndex(tab);
      this.preventActiveIndexPropagation = true;
      this.activeIndexChange.emit(selectedTabIndex);
      this.onChange.emit({
        originalEvent: event,
        index: selectedTabIndex
      });
      this.updateScrollBar(selectedTabIndex);
    }
    if (event) {
      event.preventDefault();
    }
  }
  close(event, tab) {
    if (this.controlClose) {
      this.onClose.emit({
        originalEvent: event,
        index: this.findTabIndex(tab),
        close: () => {
          this.closeTab(tab);
        }
      });
    } else {
      this.closeTab(tab);
      this.onClose.emit({
        originalEvent: event,
        index: this.findTabIndex(tab)
      });
    }
  }
  closeTab(tab) {
    if (tab.disabled) {
      return;
    }
    if (tab.selected) {
      this.tabChanged = true;
      tab.selected = false;
      for (let i = 0; i < this.tabs.length; i++) {
        let tabPanel = this.tabs[i];
        if (!tabPanel.closed && !tab.disabled) {
          tabPanel.selected = true;
          break;
        }
      }
    }
    tab.closed = true;
  }
  findSelectedTab() {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].selected) {
        return this.tabs[i];
      }
    }
    return null;
  }
  findTabIndex(tab) {
    let index = -1;
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i] == tab) {
        index = i;
        break;
      }
    }
    return index;
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  updateInkBar() {
    if (this.navbar) {
      const tabHeader = DomHandler.findSingle(this.navbar.nativeElement, 'li.p-highlight');
      if (!tabHeader) {
        return;
      }
      this.inkbar.nativeElement.style.width = DomHandler.getWidth(tabHeader) + 'px';
      this.inkbar.nativeElement.style.left = DomHandler.getOffset(tabHeader).left - DomHandler.getOffset(this.navbar.nativeElement).left + 'px';
    }
  }
  updateScrollBar(index) {
    let tabHeader = this.navbar.nativeElement.children[index];
    tabHeader.scrollIntoView({
      block: 'nearest'
    });
  }
  updateButtonState() {
    const content = this.content.nativeElement;
    const {
      scrollLeft,
      scrollWidth
    } = content;
    const width = DomHandler.getWidth(content);
    this.backwardIsDisabled = scrollLeft === 0;
    this.forwardIsDisabled = scrollLeft === scrollWidth - width;
  }
  onScroll(event) {
    this.scrollable && this.updateButtonState();
    event.preventDefault();
  }
  getVisibleButtonWidths() {
    return [this.prevBtn?.nativeElement, this.nextBtn?.nativeElement].reduce((acc, el) => el ? acc + DomHandler.getWidth(el) : acc, 0);
  }
  navBackward() {
    const content = this.content.nativeElement;
    const width = DomHandler.getWidth(content) - this.getVisibleButtonWidths();
    const pos = content.scrollLeft - width;
    content.scrollLeft = pos <= 0 ? 0 : pos;
  }
  navForward() {
    const content = this.content.nativeElement;
    const width = DomHandler.getWidth(content) - this.getVisibleButtonWidths();
    const pos = content.scrollLeft + width;
    const lastPos = content.scrollWidth - width;
    content.scrollLeft = pos >= lastPos ? lastPos : pos;
  }
  static ɵfac = function TabView_Factory(t) {
    return new (t || TabView)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: TabView,
    selectors: [["p-tabView"]],
    contentQueries: function TabView_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, TabPanel, 4);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabPanels = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function TabView_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c1, 5);
        i0.ɵɵviewQuery(_c2, 5);
        i0.ɵɵviewQuery(_c3, 5);
        i0.ɵɵviewQuery(_c4, 5);
        i0.ɵɵviewQuery(_c5, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.navbar = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.prevBtn = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nextBtn = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inkbar = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      style: "style",
      styleClass: "styleClass",
      controlClose: "controlClose",
      scrollable: "scrollable",
      activeIndex: "activeIndex"
    },
    outputs: {
      onChange: "onChange",
      onClose: "onClose",
      activeIndexChange: "activeIndexChange"
    },
    ngContentSelectors: _c0,
    decls: 13,
    vars: 9,
    consts: [[3, "ngClass", "ngStyle"], [1, "p-tabview-nav-container"], ["class", "p-tabview-nav-prev p-tabview-nav-btn p-link", "type", "button", "pRipple", "", 3, "click", 4, "ngIf"], [1, "p-tabview-nav-content", 3, "scroll"], ["content", ""], ["role", "tablist", 1, "p-tabview-nav"], ["navbar", ""], ["ngFor", "", 3, "ngForOf"], [1, "p-tabview-ink-bar"], ["inkbar", ""], ["class", "p-tabview-nav-next p-tabview-nav-btn p-link", "type", "button", "pRipple", "", 3, "click", 4, "ngIf"], [1, "p-tabview-panels"], ["type", "button", "pRipple", "", 1, "p-tabview-nav-prev", "p-tabview-nav-btn", "p-link", 3, "click"], ["prevBtn", ""], [4, "ngIf"], [4, "ngTemplateOutlet"], ["role", "presentation", 3, "ngClass", "ngStyle", "class", 4, "ngIf"], ["role", "presentation", 3, "ngClass", "ngStyle"], ["role", "tab", "pRipple", "", 1, "p-tabview-nav-link", 3, "pTooltip", "tooltipPosition", "positionStyle", "tooltipStyleClass", "click", "keydown.enter"], ["class", "p-tabview-left-icon", 3, "ngClass", 4, "ngIf"], ["class", "p-tabview-left-icon", 4, "ngIf"], [1, "p-tabview-title"], ["class", "p-tabview-right-icon", 3, "ngClass", 4, "ngIf"], ["class", "p-tabview-right-icon", 4, "ngIf"], [1, "p-tabview-left-icon", 3, "ngClass"], [1, "p-tabview-left-icon"], [1, "p-tabview-right-icon", 3, "ngClass"], [1, "p-tabview-right-icon"], [3, "styleClass", "click", 4, "ngIf"], ["class", "tab.closeIconTemplate", 4, "ngIf"], [3, "styleClass", "click"], [1, "tab.closeIconTemplate"], ["type", "button", "pRipple", "", 1, "p-tabview-nav-next", "p-tabview-nav-btn", "p-link", 3, "click"], ["nextBtn", ""]],
    template: function TabView_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
        i0.ɵɵtemplate(2, TabView_button_2_Template, 4, 2, "button", 2);
        i0.ɵɵelementStart(3, "div", 3, 4);
        i0.ɵɵlistener("scroll", function TabView_Template_div_scroll_3_listener($event) {
          return ctx.onScroll($event);
        });
        i0.ɵɵelementStart(5, "ul", 5, 6);
        i0.ɵɵtemplate(7, TabView_ng_template_7_Template, 1, 1, "ng-template", 7);
        i0.ɵɵelement(8, "li", 8, 9);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(10, TabView_button_10_Template, 4, 2, "button", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div", 11);
        i0.ɵɵprojection(12);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c7, ctx.scrollable))("ngStyle", ctx.style);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.scrollable && !ctx.backwardIsDisabled);
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngForOf", ctx.tabs);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.scrollable && !ctx.forwardIsDisabled);
      }
    },
    dependencies: function () {
      return [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.Tooltip, i3.Ripple, TimesIcon, ChevronLeftIcon, ChevronRightIcon];
    },
    styles: [".p-tabview-nav-container{position:relative}.p-tabview-scrollable .p-tabview-nav-container{overflow:hidden}.p-tabview-nav-content{overflow-x:auto;overflow-y:hidden;scroll-behavior:smooth;scrollbar-width:none;overscroll-behavior:contain auto}.p-tabview-nav{display:flex;margin:0;padding:0;list-style-type:none;flex:1 1 auto}.p-tabview-nav-link{cursor:pointer;-webkit-user-select:none;user-select:none;display:flex;align-items:center;position:relative;text-decoration:none;overflow:hidden}.p-tabview-ink-bar{display:none;z-index:1}.p-tabview-nav-link:focus{z-index:1}.p-tabview-title{line-height:1;white-space:nowrap}.p-tabview-nav-btn{position:absolute;top:0;z-index:2;height:100%;display:flex;align-items:center;justify-content:center}.p-tabview-nav-prev{left:0}.p-tabview-nav-next{right:0}.p-tabview-nav-content::-webkit-scrollbar{display:none}.p-tabview-close{z-index:1}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabView, [{
    type: Component,
    args: [{
      selector: 'p-tabView',
      template: `
        <div [ngClass]="{ 'p-tabview p-component': true, 'p-tabview-scrollable': scrollable }" [ngStyle]="style" [class]="styleClass">
            <div class="p-tabview-nav-container">
                <button *ngIf="scrollable && !backwardIsDisabled" #prevBtn class="p-tabview-nav-prev p-tabview-nav-btn p-link" (click)="navBackward()" type="button" pRipple>
                    <ChevronLeftIcon *ngIf="!previousIconTemplate" />
                    <ng-template *ngTemplateOutlet="previousIconTemplate"></ng-template>
                </button>
                <div #content class="p-tabview-nav-content" (scroll)="onScroll($event)">
                    <ul #navbar class="p-tabview-nav" role="tablist">
                        <ng-template ngFor let-tab [ngForOf]="tabs">
                            <li role="presentation" [ngClass]="{ 'p-highlight': tab.selected, 'p-disabled': tab.disabled }" [ngStyle]="tab.headerStyle" [class]="tab.headerStyleClass" *ngIf="!tab.closed">
                                <a
                                    role="tab"
                                    class="p-tabview-nav-link"
                                    [attr.id]="tab.id + '-label'"
                                    [attr.aria-selected]="tab.selected"
                                    [attr.aria-controls]="tab.id"
                                    [pTooltip]="tab.tooltip"
                                    [tooltipPosition]="tab.tooltipPosition"
                                    [attr.aria-selected]="tab.selected"
                                    [positionStyle]="tab.tooltipPositionStyle"
                                    [tooltipStyleClass]="tab.tooltipStyleClass"
                                    (click)="open($event, tab)"
                                    (keydown.enter)="open($event, tab)"
                                    pRipple
                                    [attr.tabindex]="tab.disabled ? null : '0'"
                                >
                                    <ng-container *ngIf="!tab.headerTemplate">
                                        <span class="p-tabview-left-icon" [ngClass]="tab.leftIcon" *ngIf="tab.leftIcon && !tab.leftIconTemplate"></span>
                                        <span *ngIf="tab.leftIconTemplate" class="p-tabview-left-icon">
                                            <ng-template *ngTemplateOutlet="tab.leftIconTemplate"></ng-template>
                                        </span>
                                        <span class="p-tabview-title">{{ tab.header }}</span>
                                        <span class="p-tabview-right-icon" [ngClass]="tab.rightIcon" *ngIf="tab.rightIcon && !tab.rightIconTemplate"></span>
                                        <span *ngIf="tab.rightIconTemplate" class="p-tabview-right-icon">
                                            <ng-template *ngTemplateOutlet="tab.rightIconTemplate"></ng-template>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngTemplateOutlet="tab.headerTemplate"></ng-container>
                                    <ng-container *ngIf="tab.closable">
                                        <TimesIcon *ngIf="!tab.closeIconTemplate" [styleClass]="'p-tabview-close'" (click)="close($event, tab)" />
                                        <span class="tab.closeIconTemplate" *ngIf="p - tabview - close"></span>
                                        <ng-template *ngTemplateOutlet="tab.closeIconTemplate"></ng-template>
                                    </ng-container>
                                </a>
                            </li>
                        </ng-template>
                        <li #inkbar class="p-tabview-ink-bar"></li>
                    </ul>
                </div>
                <button *ngIf="scrollable && !forwardIsDisabled" #nextBtn class="p-tabview-nav-next p-tabview-nav-btn p-link" (click)="navForward()" type="button" pRipple>
                    <ChevronRightIcon *ngIf="!nextIconTemplate" />
                    <ng-template *ngTemplateOutlet="nextIconTemplate"></ng-template>
                </button>
            </div>
            <div class="p-tabview-panels">
                <ng-content></ng-content>
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-tabview-nav-container{position:relative}.p-tabview-scrollable .p-tabview-nav-container{overflow:hidden}.p-tabview-nav-content{overflow-x:auto;overflow-y:hidden;scroll-behavior:smooth;scrollbar-width:none;overscroll-behavior:contain auto}.p-tabview-nav{display:flex;margin:0;padding:0;list-style-type:none;flex:1 1 auto}.p-tabview-nav-link{cursor:pointer;-webkit-user-select:none;user-select:none;display:flex;align-items:center;position:relative;text-decoration:none;overflow:hidden}.p-tabview-ink-bar{display:none;z-index:1}.p-tabview-nav-link:focus{z-index:1}.p-tabview-title{line-height:1;white-space:nowrap}.p-tabview-nav-btn{position:absolute;top:0;z-index:2;height:100%;display:flex;align-items:center;justify-content:center}.p-tabview-nav-prev{left:0}.p-tabview-nav-next{right:0}.p-tabview-nav-content::-webkit-scrollbar{display:none}.p-tabview-close{z-index:1}\n"]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    controlClose: [{
      type: Input
    }],
    scrollable: [{
      type: Input
    }],
    activeIndex: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }],
    onClose: [{
      type: Output
    }],
    activeIndexChange: [{
      type: Output
    }],
    content: [{
      type: ViewChild,
      args: ['content']
    }],
    navbar: [{
      type: ViewChild,
      args: ['navbar']
    }],
    prevBtn: [{
      type: ViewChild,
      args: ['prevBtn']
    }],
    nextBtn: [{
      type: ViewChild,
      args: ['nextBtn']
    }],
    inkbar: [{
      type: ViewChild,
      args: ['inkbar']
    }],
    tabPanels: [{
      type: ContentChildren,
      args: [TabPanel]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class TabViewModule {
  static ɵfac = function TabViewModule_Factory(t) {
    return new (t || TabViewModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TabViewModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule, TooltipModule, RippleModule, TimesIcon, ChevronLeftIcon, ChevronRightIcon, SharedModule]
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabViewModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedModule, TooltipModule, RippleModule, TimesIcon, ChevronLeftIcon, ChevronRightIcon],
      exports: [TabView, TabPanel, SharedModule],
      declarations: [TabView, TabPanel]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TabPanel, TabView, TabViewModule };
