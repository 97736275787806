import { trigger, transition, style, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, Inject, Input, ViewChild, Output, ChangeDetectionStrategy, ContentChildren, NgModule } from '@angular/core';
import * as i2 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i5 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler, ConnectedOverlayScrollHandler } from 'primeng/dom';
import { AngleRightIcon } from 'primeng/icons/angleright';
import * as i3 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import * as i4 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { ZIndexUtils } from 'primeng/utils';
const _c0 = ["sublist"];
const _c1 = function (a0) {
  return {
    "p-hidden": a0
  };
};
function TieredMenuSub_ng_template_2_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 5);
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c1, child_r2.visible === false));
  }
}
function TieredMenuSub_ng_template_2_li_1_a_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 17);
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r2.icon)("ngStyle", child_r2.iconStyle);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(child_r2.label);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_2_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", child_r2.label, i0.ɵɵsanitizeHtml);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_2_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 20);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r2.badgeStyleClass);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(child_r2.badge);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_AngleRightIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleRightIcon", 23);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-submenu-icon");
  }
}
function TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_2_ng_template_0_Template(rf, ctx) {}
function TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_2_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_AngleRightIcon_1_Template, 1, 1, "AngleRightIcon", 21);
    i0.ɵɵtemplate(2, TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_2_Template, 1, 0, null, 22);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r15.tieredMenu.submenuIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r15.tieredMenu.submenuIconTemplate);
  }
}
const _c2 = function (a1) {
  return {
    "p-menuitem-link": true,
    "p-disabled": a1
  };
};
function TieredMenuSub_ng_template_2_li_1_a_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r25 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 11);
    i0.ɵɵlistener("keydown", function TieredMenuSub_ng_template_2_li_1_a_2_Template_a_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r25);
      const child_r2 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r23 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r23.onItemKeyDown($event, child_r2));
    })("click", function TieredMenuSub_ng_template_2_li_1_a_2_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r25);
      const child_r2 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r26 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r26.onItemClick($event, child_r2));
    })("mouseenter", function TieredMenuSub_ng_template_2_li_1_a_2_Template_a_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r25);
      const child_r2 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r28 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r28.onItemMouseEnter($event, child_r2));
    });
    i0.ɵɵtemplate(1, TieredMenuSub_ng_template_2_li_1_a_2_span_1_Template, 1, 2, "span", 12);
    i0.ɵɵtemplate(2, TieredMenuSub_ng_template_2_li_1_a_2_span_2_Template, 2, 1, "span", 13);
    i0.ɵɵtemplate(3, TieredMenuSub_ng_template_2_li_1_a_2_ng_template_3_Template, 1, 1, "ng-template", null, 14, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵtemplate(5, TieredMenuSub_ng_template_2_li_1_a_2_span_5_Template, 2, 2, "span", 15);
    i0.ɵɵtemplate(6, TieredMenuSub_ng_template_2_li_1_a_2_ng_container_6_Template, 3, 2, "ng-container", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r12 = i0.ɵɵreference(4);
    const child_r2 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵproperty("target", child_r2.target)("ngClass", i0.ɵɵpureFunction1(14, _c2, child_r2.disabled));
    i0.ɵɵattribute("href", child_r2.url, i0.ɵɵsanitizeUrl)("data-automationid", child_r2.automationId)("title", child_r2.title)("id", child_r2.id)("tabindex", child_r2.disabled ? null : "0")("aria-haspopup", ctx_r7.item.items != null)("aria-expanded", ctx_r7.item === ctx_r7.activeItem);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.escape !== false)("ngIfElse", _r12);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", child_r2.badge);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.items);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 17);
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r2.icon)("ngStyle", child_r2.iconStyle);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_3_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(child_r2.label);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_3_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("innerHTML", child_r2.label, i0.ɵɵsanitizeHtml);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_3_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 20);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", child_r2.badgeStyleClass);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(child_r2.badge);
  }
}
function TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_AngleRightIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleRightIcon", 23);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-submenu-icon");
  }
}
function TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_2_ng_template_0_Template(rf, ctx) {}
function TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_2_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_AngleRightIcon_1_Template, 1, 1, "AngleRightIcon", 21);
    i0.ɵɵtemplate(2, TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_2_Template, 1, 0, null, 22);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r36 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r36.tieredMenu.submenuIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r36.tieredMenu.submenuIconTemplate);
  }
}
const _c3 = function () {
  return {
    exact: false
  };
};
function TieredMenuSub_ng_template_2_li_1_a_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r46 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 24);
    i0.ɵɵlistener("keydown", function TieredMenuSub_ng_template_2_li_1_a_3_Template_a_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r46);
      const child_r2 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r44 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r44.onItemKeyDown($event, child_r2));
    })("click", function TieredMenuSub_ng_template_2_li_1_a_3_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r46);
      const child_r2 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r47 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r47.onItemClick($event, child_r2));
    })("mouseenter", function TieredMenuSub_ng_template_2_li_1_a_3_Template_a_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r46);
      const child_r2 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r49 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r49.onItemMouseEnter($event, child_r2));
    });
    i0.ɵɵtemplate(1, TieredMenuSub_ng_template_2_li_1_a_3_span_1_Template, 1, 2, "span", 12);
    i0.ɵɵtemplate(2, TieredMenuSub_ng_template_2_li_1_a_3_span_2_Template, 2, 1, "span", 13);
    i0.ɵɵtemplate(3, TieredMenuSub_ng_template_2_li_1_a_3_ng_template_3_Template, 1, 1, "ng-template", null, 25, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵtemplate(5, TieredMenuSub_ng_template_2_li_1_a_3_span_5_Template, 2, 2, "span", 15);
    i0.ɵɵtemplate(6, TieredMenuSub_ng_template_2_li_1_a_3_ng_container_6_Template, 3, 2, "ng-container", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r33 = i0.ɵɵreference(4);
    const child_r2 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("routerLink", child_r2.routerLink)("queryParams", child_r2.queryParams)("routerLinkActive", "p-menuitem-link-active")("routerLinkActiveOptions", child_r2.routerLinkActiveOptions || i0.ɵɵpureFunction0(21, _c3))("target", child_r2.target)("ngClass", i0.ɵɵpureFunction1(22, _c2, child_r2.disabled))("fragment", child_r2.fragment)("queryParamsHandling", child_r2.queryParamsHandling)("preserveFragment", child_r2.preserveFragment)("skipLocationChange", child_r2.skipLocationChange)("replaceUrl", child_r2.replaceUrl)("state", child_r2.state);
    i0.ɵɵattribute("data-automationid", child_r2.automationId)("title", child_r2.title)("id", child_r2.id)("tabindex", child_r2.disabled ? null : "0");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.escape !== false)("ngIfElse", _r33);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", child_r2.badge);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.items);
  }
}
function TieredMenuSub_ng_template_2_li_1_p_tieredMenuSub_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r53 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-tieredMenuSub", 26);
    i0.ɵɵlistener("keydownItem", function TieredMenuSub_ng_template_2_li_1_p_tieredMenuSub_4_Template_p_tieredMenuSub_keydownItem_0_listener($event) {
      i0.ɵɵrestoreView(_r53);
      const ctx_r52 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r52.onChildItemKeyDown($event));
    })("leafClick", function TieredMenuSub_ng_template_2_li_1_p_tieredMenuSub_4_Template_p_tieredMenuSub_leafClick_0_listener() {
      i0.ɵɵrestoreView(_r53);
      const ctx_r54 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r54.onLeafClick());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵproperty("parentActive", child_r2 === ctx_r9.activeItem)("item", child_r2)("mobileActive", ctx_r9.mobileActive)("autoDisplay", ctx_r9.autoDisplay)("popup", ctx_r9.popup);
  }
}
const _c4 = function (a1, a2) {
  return {
    "p-menuitem": true,
    "p-menuitem-active": a1,
    "p-hidden": a2
  };
};
function TieredMenuSub_ng_template_2_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 6, 7);
    i0.ɵɵtemplate(2, TieredMenuSub_ng_template_2_li_1_a_2_Template, 7, 16, "a", 8);
    i0.ɵɵtemplate(3, TieredMenuSub_ng_template_2_li_1_a_3_Template, 7, 24, "a", 9);
    i0.ɵɵtemplate(4, TieredMenuSub_ng_template_2_li_1_p_tieredMenuSub_4_Template, 1, 5, "p-tieredMenuSub", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(child_r2.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(8, _c4, child_r2 === ctx_r4.activeItem, child_r2.visible === false))("ngStyle", child_r2.style)("tooltipOptions", child_r2.tooltipOptions);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !child_r2.routerLink);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.routerLink);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", child_r2.items);
  }
}
function TieredMenuSub_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TieredMenuSub_ng_template_2_li_0_Template, 1, 3, "li", 3);
    i0.ɵɵtemplate(1, TieredMenuSub_ng_template_2_li_1_Template, 5, 11, "li", 4);
  }
  if (rf & 2) {
    const child_r2 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", child_r2.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !child_r2.separator);
  }
}
const _c5 = function (a0) {
  return {
    "p-submenu-list": a0
  };
};
const _c6 = function (a1) {
  return {
    "p-tieredmenu p-component": true,
    "p-tieredmenu-overlay": a1
  };
};
const _c7 = function (a0, a1) {
  return {
    showTransitionParams: a0,
    hideTransitionParams: a1
  };
};
const _c8 = function (a1) {
  return {
    value: "visible",
    params: a1
  };
};
function TieredMenu_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function TieredMenu_div_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onOverlayClick($event));
    })("@overlayAnimation.start", function TieredMenu_div_0_Template_div_animation_overlayAnimation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onOverlayAnimationStart($event));
    })("@overlayAnimation.done", function TieredMenu_div_0_Template_div_animation_overlayAnimation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onOverlayAnimationEnd($event));
    });
    i0.ɵɵelementStart(1, "p-tieredMenuSub", 2);
    i0.ɵɵlistener("leafClick", function TieredMenu_div_0_Template_p_tieredMenuSub_leafClick_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onLeafClick());
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(12, _c6, ctx_r0.popup))("ngStyle", ctx_r0.style)("@overlayAnimation", i0.ɵɵpureFunction1(17, _c8, i0.ɵɵpureFunction2(14, _c7, ctx_r0.showTransitionOptions, ctx_r0.hideTransitionOptions)))("@.disabled", ctx_r0.popup !== true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("item", ctx_r0.model)("parentActive", ctx_r0.parentActive)("baseZIndex", ctx_r0.baseZIndex)("autoZIndex", ctx_r0.autoZIndex)("autoDisplay", ctx_r0.autoDisplay)("popup", ctx_r0.popup);
  }
}
class TieredMenuSub {
  document;
  el;
  renderer;
  cd;
  tieredMenu;
  item;
  root;
  autoDisplay;
  autoZIndex = true;
  baseZIndex = 0;
  mobileActive;
  popup;
  get parentActive() {
    return this._parentActive;
  }
  set parentActive(value) {
    if (!this.root) {
      this._parentActive = value;
      if (!value) this.activeItem = null;else this.positionSubmenu();
    }
  }
  sublistViewChild;
  leafClick = new EventEmitter();
  keydownItem = new EventEmitter();
  _parentActive;
  documentClickListener;
  menuHoverActive = false;
  activeItem;
  constructor(document, el, renderer, cd, tieredMenu) {
    this.document = document;
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.tieredMenu = tieredMenu;
  }
  onItemClick(event, item) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }
    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item
      });
    }
    if (item.items) {
      if (this.activeItem && item === this.activeItem) {
        this.activeItem = null;
        this.unbindDocumentClickListener();
      } else {
        this.activeItem = item;
        if (this.root) {
          this.bindDocumentClickListener();
        }
      }
    }
    if (!item.items) {
      this.onLeafClick();
    }
  }
  onItemMouseEnter(event, item) {
    if (item.disabled || this.mobileActive) {
      event.preventDefault();
      return;
    }
    if (this.root) {
      if (this.activeItem || this.autoDisplay || this.popup) {
        this.activeItem = item;
        this.bindDocumentClickListener();
      }
    } else {
      this.activeItem = item;
      this.bindDocumentClickListener();
    }
  }
  onLeafClick() {
    this.activeItem = null;
    if (this.root) {
      this.unbindDocumentClickListener();
    }
    this.leafClick.emit();
  }
  onItemKeyDown(event, item) {
    let listItem = event.currentTarget.parentElement;
    switch (event.key) {
      case 'ArrowDown':
        const nextItem = this.findNextItem(listItem);
        if (nextItem) {
          nextItem.children[0].focus();
        }
        event.preventDefault();
        break;
      case 'ArrowUp':
        const prevItem = this.findPrevItem(listItem);
        if (prevItem) {
          prevItem.children[0].focus();
        }
        event.preventDefault();
        break;
      case 'ArrowRight':
        if (item.items) {
          this.activeItem = item;
          if (this.root) {
            this.bindDocumentClickListener();
          }
          setTimeout(() => {
            (listItem?.children[1].children[0].children[0].children[0]).focus();
          }, 50);
        }
        event.preventDefault();
        break;
      case 'Enter':
        if (!item.routerLink) {
          this.onItemClick(event, item);
        }
        break;
      default:
        break;
    }
    this.keydownItem.emit({
      originalEvent: event,
      element: listItem
    });
  }
  positionSubmenu() {
    let sublist = this.sublistViewChild && this.sublistViewChild.nativeElement;
    if (sublist) {
      const parentItem = sublist.parentElement.parentElement;
      const containerOffset = DomHandler.getOffset(parentItem);
      const viewport = DomHandler.getViewport();
      const sublistWidth = sublist.offsetParent ? sublist.offsetWidth : DomHandler.getHiddenElementOuterWidth(sublist);
      const itemOuterWidth = DomHandler.getOuterWidth(parentItem.children[0]);
      if (parseInt(containerOffset.left, 10) + itemOuterWidth + sublistWidth > viewport.width - DomHandler.calculateScrollbarWidth()) {
        DomHandler.addClass(sublist, 'p-submenu-list-flipped');
      }
    }
  }
  findNextItem(item) {
    let nextItem = item.nextElementSibling;
    if (nextItem) return DomHandler.hasClass(nextItem, 'p-disabled') || !DomHandler.hasClass(nextItem, 'p-menuitem') ? this.findNextItem(nextItem) : nextItem;else return null;
  }
  findPrevItem(item) {
    let prevItem = item.previousElementSibling;
    if (prevItem) return DomHandler.hasClass(prevItem, 'p-disabled') || !DomHandler.hasClass(prevItem, 'p-menuitem') ? this.findPrevItem(prevItem) : prevItem;else return null;
  }
  onChildItemKeyDown(event) {
    if (event.key === 'ArrowLeft') {
      this.activeItem = null;
      if (this.root) {
        this.unbindDocumentClickListener();
      }
      if (event.target) {
        const parent = event.target.parentElement;
        if (parent && parent.parentElement && parent.parentElement.parentElement) {
          parent.parentElement.parentElement.children[0].focus();
        }
      }
    }
  }
  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen(this.document, 'click', event => {
        if (this.el && !this.el.nativeElement.contains(event.target)) {
          this.activeItem = null;
          this.cd.markForCheck();
          this.unbindDocumentClickListener();
        }
      });
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  ngOnDestroy() {
    this.unbindDocumentClickListener();
  }
  static ɵfac = function TieredMenuSub_Factory(t) {
    return new (t || TieredMenuSub)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(TieredMenu));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: TieredMenuSub,
    selectors: [["p-tieredMenuSub"]],
    viewQuery: function TieredMenuSub_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sublistViewChild = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      item: "item",
      root: "root",
      autoDisplay: "autoDisplay",
      autoZIndex: "autoZIndex",
      baseZIndex: "baseZIndex",
      mobileActive: "mobileActive",
      popup: "popup",
      parentActive: "parentActive"
    },
    outputs: {
      leafClick: "leafClick",
      keydownItem: "keydownItem"
    },
    decls: 3,
    vars: 4,
    consts: [[3, "ngClass"], ["sublist", ""], ["ngFor", "", 3, "ngForOf"], ["class", "p-menu-separator", 3, "ngClass", 4, "ngIf"], ["pTooltip", "", 3, "ngClass", "ngStyle", "class", "tooltipOptions", 4, "ngIf"], [1, "p-menu-separator", 3, "ngClass"], ["pTooltip", "", 3, "ngClass", "ngStyle", "tooltipOptions"], ["listItem", ""], ["pRipple", "", 3, "target", "ngClass", "keydown", "click", "mouseenter", 4, "ngIf"], ["role", "menuitem", "pRipple", "", 3, "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "keydown", "click", "mouseenter", 4, "ngIf"], [3, "parentActive", "item", "mobileActive", "autoDisplay", "popup", "keydownItem", "leafClick", 4, "ngIf"], ["pRipple", "", 3, "target", "ngClass", "keydown", "click", "mouseenter"], ["class", "p-menuitem-icon", 3, "ngClass", "ngStyle", 4, "ngIf"], ["class", "p-menuitem-text", 4, "ngIf", "ngIfElse"], ["htmlLabel", ""], ["class", "p-menuitem-badge", 3, "ngClass", 4, "ngIf"], [4, "ngIf"], [1, "p-menuitem-icon", 3, "ngClass", "ngStyle"], [1, "p-menuitem-text"], [1, "p-menuitem-text", 3, "innerHTML"], [1, "p-menuitem-badge", 3, "ngClass"], [3, "styleClass", 4, "ngIf"], [4, "ngTemplateOutlet"], [3, "styleClass"], ["role", "menuitem", "pRipple", "", 3, "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "keydown", "click", "mouseenter"], ["htmlRouteLabel", ""], [3, "parentActive", "item", "mobileActive", "autoDisplay", "popup", "keydownItem", "leafClick"]],
    template: function TieredMenuSub_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "ul", 0, 1);
        i0.ɵɵtemplate(2, TieredMenuSub_ng_template_2_Template, 2, 2, "ng-template", 2);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c5, !ctx.root));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.root ? ctx.item : ctx.item == null ? null : ctx.item.items);
      }
    },
    dependencies: function () {
      return [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.RouterLink, i2.RouterLinkActive, i3.Ripple, i4.Tooltip, AngleRightIcon, TieredMenuSub];
    },
    encapsulation: 2
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TieredMenuSub, [{
    type: Component,
    args: [{
      selector: 'p-tieredMenuSub',
      template: `
        <ul #sublist [ngClass]="{ 'p-submenu-list': !root }">
            <ng-template ngFor let-child [ngForOf]="root ? item : item?.items">
                <li *ngIf="child.separator" class="p-menu-separator" [ngClass]="{ 'p-hidden': child.visible === false }"></li>
                <li
                    *ngIf="!child.separator"
                    #listItem
                    [ngClass]="{ 'p-menuitem': true, 'p-menuitem-active': child === activeItem, 'p-hidden': child.visible === false }"
                    [ngStyle]="child.style"
                    [class]="child.styleClass"
                    pTooltip
                    [tooltipOptions]="child.tooltipOptions"
                >
                    <a
                        *ngIf="!child.routerLink"
                        (keydown)="onItemKeyDown($event, child)"
                        [attr.href]="child.url"
                        [attr.data-automationid]="child.automationId"
                        [target]="child.target"
                        [attr.title]="child.title"
                        [attr.id]="child.id"
                        (click)="onItemClick($event, child)"
                        (mouseenter)="onItemMouseEnter($event, child)"
                        [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': child.disabled }"
                        [attr.tabindex]="child.disabled ? null : '0'"
                        [attr.aria-haspopup]="item.items != null"
                        [attr.aria-expanded]="item === activeItem"
                        pRipple
                    >
                        <span class="p-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon" [ngStyle]="child.iconStyle"></span>
                        <span class="p-menuitem-text" *ngIf="child.escape !== false; else htmlLabel">{{ child.label }}</span>
                        <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
                        <span class="p-menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{ child.badge }}</span>
                        <ng-container *ngIf="child.items">
                            <AngleRightIcon *ngIf="!tieredMenu.submenuIconTemplate" [styleClass]="'p-submenu-icon'" />
                            <ng-template *ngTemplateOutlet="tieredMenu.submenuIconTemplate"></ng-template>
                        </ng-container>
                    </a>
                    <a
                        *ngIf="child.routerLink"
                        (keydown)="onItemKeyDown($event, child)"
                        [routerLink]="child.routerLink"
                        [attr.data-automationid]="child.automationId"
                        [queryParams]="child.queryParams"
                        [routerLinkActive]="'p-menuitem-link-active'"
                        [routerLinkActiveOptions]="child.routerLinkActiveOptions || { exact: false }"
                        [target]="child.target"
                        [attr.title]="child.title"
                        [attr.id]="child.id"
                        [attr.tabindex]="child.disabled ? null : '0'"
                        role="menuitem"
                        (click)="onItemClick($event, child)"
                        (mouseenter)="onItemMouseEnter($event, child)"
                        [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': child.disabled }"
                        [fragment]="child.fragment"
                        [queryParamsHandling]="child.queryParamsHandling"
                        [preserveFragment]="child.preserveFragment"
                        [skipLocationChange]="child.skipLocationChange"
                        [replaceUrl]="child.replaceUrl"
                        [state]="child.state"
                        pRipple
                    >
                        <span class="p-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon" [ngStyle]="child.iconStyle"></span>
                        <span class="p-menuitem-text" *ngIf="child.escape !== false; else htmlRouteLabel">{{ child.label }}</span>
                        <ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
                        <span class="p-menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{ child.badge }}</span>
                        <ng-container *ngIf="child.items">
                            <AngleRightIcon *ngIf="!tieredMenu.submenuIconTemplate" [styleClass]="'p-submenu-icon'" />
                            <ng-template *ngTemplateOutlet="tieredMenu.submenuIconTemplate"></ng-template>
                        </ng-container>
                    </a>
                    <p-tieredMenuSub
                        (keydownItem)="onChildItemKeyDown($event)"
                        [parentActive]="child === activeItem"
                        [item]="child"
                        *ngIf="child.items"
                        [mobileActive]="mobileActive"
                        [autoDisplay]="autoDisplay"
                        (leafClick)="onLeafClick()"
                        [popup]="popup"
                    ></p-tieredMenuSub>
                </li>
            </ng-template>
        </ul>
    `,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: TieredMenu
    }];
  }, {
    item: [{
      type: Input
    }],
    root: [{
      type: Input
    }],
    autoDisplay: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input
    }],
    baseZIndex: [{
      type: Input
    }],
    mobileActive: [{
      type: Input
    }],
    popup: [{
      type: Input
    }],
    parentActive: [{
      type: Input
    }],
    sublistViewChild: [{
      type: ViewChild,
      args: ['sublist']
    }],
    leafClick: [{
      type: Output
    }],
    keydownItem: [{
      type: Output
    }]
  });
})();
/**
 * TieredMenu displays submenus in nested overlays.
 * @group Components
 */
class TieredMenu {
  document;
  el;
  renderer;
  cd;
  config;
  overlayService;
  /**
   * An array of menuitems.
   * @group Props
   */
  model;
  /**
   * Defines if menu would displayed as a popup.
   * @group Props
   */
  popup;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element.
   * @group Props
   */
  appendTo;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex = 0;
  /**
   * Whether to show a root submenu on mouse over.
   * @group Props
   */
  autoDisplay;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  hideTransitionOptions = '.1s linear';
  /**
   * Callback to invoke when overlay menu is shown.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Callback to invoke when overlay menu is hidden.
   * @group Emits
   */
  onHide = new EventEmitter();
  templates;
  submenuIconTemplate;
  parentActive;
  container;
  documentClickListener;
  documentResizeListener;
  preventDocumentDefault;
  scrollHandler;
  target;
  visible;
  relativeAlign;
  window;
  constructor(document, el, renderer, cd, config, overlayService) {
    this.document = document;
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.config = config;
    this.overlayService = overlayService;
    this.window = this.document.defaultView;
  }
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'submenuicon':
          this.submenuIconTemplate = item.template;
          break;
      }
    });
  }
  /**
   * Toggles the visibility of the popup menu.
   * @param {Event} event - Browser event.
   * @group Method
   */
  toggle(event) {
    if (this.visible) this.hide();else this.show(event);
    this.preventDocumentDefault = true;
  }
  /**
   * Displays the popup menu.
   * @param {Event} even - Browser event.
   * @group Method
   */
  show(event) {
    this.target = event.currentTarget;
    this.relativeAlign = event.relativeAlign;
    this.visible = true;
    this.parentActive = true;
    this.preventDocumentDefault = true;
    this.cd.markForCheck();
  }
  onOverlayClick(event) {
    if (this.popup) {
      this.overlayService.add({
        originalEvent: event,
        target: this.el.nativeElement
      });
    }
    this.preventDocumentDefault = true;
  }
  onOverlayAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        if (this.popup) {
          this.container = event.element;
          this.moveOnTop();
          this.onShow.emit({});
          this.appendOverlay();
          this.alignOverlay();
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
          this.bindScrollListener();
        }
        break;
      case 'void':
        this.onOverlayHide();
        this.onHide.emit({});
        break;
    }
  }
  alignOverlay() {
    if (this.relativeAlign) DomHandler.relativePosition(this.container, this.target);else DomHandler.absolutePosition(this.container, this.target);
  }
  onOverlayAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(event.element);
        break;
    }
  }
  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.renderer.appendChild(this.document.body, this.container);else DomHandler.appendChild(this.container, this.appendTo);
    }
  }
  restoreOverlayAppend() {
    if (this.container && this.appendTo) {
      this.renderer.appendChild(this.el.nativeElement, this.container);
    }
  }
  moveOnTop() {
    if (this.autoZIndex) {
      ZIndexUtils.set('menu', this.container, this.baseZIndex + this.config.zIndex.menu);
    }
  }
  /**
   * Hides the popup menu.
   * @group Method
   */
  hide() {
    this.visible = false;
    this.relativeAlign = false;
    this.parentActive = false;
    this.cd.markForCheck();
  }
  onWindowResize() {
    if (this.visible && !DomHandler.isTouchDevice()) {
      this.hide();
    }
  }
  onLeafClick() {
    if (this.popup) {
      this.hide();
    }
    this.unbindDocumentClickListener();
  }
  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      const documentTarget = this.el ? this.el.nativeElement.ownerDocument : 'document';
      this.documentClickListener = this.renderer.listen(documentTarget, 'click', () => {
        if (!this.preventDocumentDefault && this.popup) {
          this.hide();
        }
        this.preventDocumentDefault = false;
      });
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  bindDocumentResizeListener() {
    if (!this.documentResizeListener) {
      this.documentResizeListener = this.renderer.listen(this.window, 'resize', this.onWindowResize.bind(this));
    }
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      this.documentResizeListener();
      this.documentResizeListener = null;
    }
  }
  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.target, () => {
        if (this.visible) {
          this.hide();
        }
      });
    }
    this.scrollHandler.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
    this.preventDocumentDefault = false;
    if (!this.cd.destroyed) {
      this.target = null;
    }
  }
  ngOnDestroy() {
    if (this.popup) {
      if (this.scrollHandler) {
        this.scrollHandler.destroy();
        this.scrollHandler = null;
      }
      if (this.container && this.autoZIndex) {
        ZIndexUtils.clear(this.container);
      }
      this.restoreOverlayAppend();
      this.onOverlayHide();
    }
  }
  static ɵfac = function TieredMenu_Factory(t) {
    return new (t || TieredMenu)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i5.PrimeNGConfig), i0.ɵɵdirectiveInject(i5.OverlayService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: TieredMenu,
    selectors: [["p-tieredMenu"]],
    contentQueries: function TieredMenu_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      model: "model",
      popup: "popup",
      style: "style",
      styleClass: "styleClass",
      appendTo: "appendTo",
      autoZIndex: "autoZIndex",
      baseZIndex: "baseZIndex",
      autoDisplay: "autoDisplay",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions"
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide"
    },
    decls: 1,
    vars: 1,
    consts: [[3, "ngClass", "class", "ngStyle", "click", 4, "ngIf"], [3, "ngClass", "ngStyle", "click"], ["root", "root", 3, "item", "parentActive", "baseZIndex", "autoZIndex", "autoDisplay", "popup", "leafClick"]],
    template: function TieredMenu_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, TieredMenu_div_0_Template, 2, 19, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", !ctx.popup || ctx.visible);
      }
    },
    dependencies: [i1.NgClass, i1.NgIf, i1.NgStyle, TieredMenuSub],
    styles: [".p-tieredmenu-overlay{position:absolute;top:0;left:0}.p-tieredmenu ul{margin:0;padding:0;list-style:none}.p-tieredmenu .p-submenu-list{position:absolute;min-width:100%;z-index:1;display:none}.p-tieredmenu .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-tieredmenu .p-menuitem-text{line-height:1}.p-tieredmenu .p-menuitem{position:relative}.p-tieredmenu .p-menuitem-link .p-submenu-icon:not(svg){margin-left:auto}.p-tieredmenu .p-menuitem-link .p-icon-wrapper{margin-left:auto}.p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list{display:block;left:100%;top:0}.p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list.p-submenu-list-flipped{left:-100%}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])]
    },
    changeDetection: 0
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TieredMenu, [{
    type: Component,
    args: [{
      selector: 'p-tieredMenu',
      template: `
        <div
            [ngClass]="{ 'p-tieredmenu p-component': true, 'p-tieredmenu-overlay': popup }"
            [class]="styleClass"
            [ngStyle]="style"
            (click)="onOverlayClick($event)"
            [@overlayAnimation]="{ value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
            [@.disabled]="popup !== true"
            (@overlayAnimation.start)="onOverlayAnimationStart($event)"
            (@overlayAnimation.done)="onOverlayAnimationEnd($event)"
            *ngIf="!popup || visible"
        >
            <p-tieredMenuSub [item]="model" root="root" [parentActive]="parentActive" [baseZIndex]="baseZIndex" [autoZIndex]="autoZIndex" (leafClick)="onLeafClick()" [autoDisplay]="autoDisplay" [popup]="popup"></p-tieredMenuSub>
        </div>
    `,
      animations: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-tieredmenu-overlay{position:absolute;top:0;left:0}.p-tieredmenu ul{margin:0;padding:0;list-style:none}.p-tieredmenu .p-submenu-list{position:absolute;min-width:100%;z-index:1;display:none}.p-tieredmenu .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-tieredmenu .p-menuitem-text{line-height:1}.p-tieredmenu .p-menuitem{position:relative}.p-tieredmenu .p-menuitem-link .p-submenu-icon:not(svg){margin-left:auto}.p-tieredmenu .p-menuitem-link .p-icon-wrapper{margin-left:auto}.p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list{display:block;left:100%;top:0}.p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list.p-submenu-list-flipped{left:-100%}\n"]
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i5.PrimeNGConfig
    }, {
      type: i5.OverlayService
    }];
  }, {
    model: [{
      type: Input
    }],
    popup: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input
    }],
    baseZIndex: [{
      type: Input
    }],
    autoDisplay: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class TieredMenuModule {
  static ɵfac = function TieredMenuModule_Factory(t) {
    return new (t || TieredMenuModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TieredMenuModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, RouterModule, RippleModule, TooltipModule, AngleRightIcon, SharedModule, RouterModule, TooltipModule, SharedModule]
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TieredMenuModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, RippleModule, TooltipModule, AngleRightIcon, SharedModule],
      exports: [TieredMenu, RouterModule, TooltipModule, SharedModule],
      declarations: [TieredMenu, TieredMenuSub]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TieredMenu, TieredMenuModule, TieredMenuSub };
