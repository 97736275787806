import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, Output, ViewChild, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { TimesCircleIcon } from 'primeng/icons/timescircle';
import { TimesIcon } from 'primeng/icons/times';
const _c0 = ["inputtext"];
function Chips_li_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Chips_li_2_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r7.field ? ctx_r7.resolveFieldData(item_r3, ctx_r7.field) : item_r3);
  }
}
function Chips_li_2_ng_container_4_TimesCircleIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "TimesCircleIcon", 14);
    i0.ɵɵlistener("click", function Chips_li_2_ng_container_4_TimesCircleIcon_1_Template_TimesCircleIcon_click_0_listener($event) {
      i0.ɵɵrestoreView(_r14);
      const i_r4 = i0.ɵɵnextContext(2).index;
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.removeItem($event, i_r4));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-chips-token-icon");
  }
}
function Chips_li_2_ng_container_4_span_2_1_ng_template_0_Template(rf, ctx) {}
function Chips_li_2_ng_container_4_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Chips_li_2_ng_container_4_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Chips_li_2_ng_container_4_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 15);
    i0.ɵɵlistener("click", function Chips_li_2_ng_container_4_span_2_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const i_r4 = i0.ɵɵnextContext(2).index;
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.removeItem($event, i_r4));
    });
    i0.ɵɵtemplate(1, Chips_li_2_ng_container_4_span_2_1_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r11.removeTokenIconTemplate);
  }
}
function Chips_li_2_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Chips_li_2_ng_container_4_TimesCircleIcon_1_Template, 1, 1, "TimesCircleIcon", 12);
    i0.ɵɵtemplate(2, Chips_li_2_ng_container_4_span_2_Template, 2, 1, "span", 13);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r8.removeTokenIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r8.removeTokenIconTemplate);
  }
}
const _c1 = function (a0) {
  return {
    $implicit: a0
  };
};
function Chips_li_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r21 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 7, 8);
    i0.ɵɵlistener("click", function Chips_li_2_Template_li_click_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r21);
      const item_r3 = restoredCtx.$implicit;
      const ctx_r20 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r20.onItemClick($event, item_r3));
    });
    i0.ɵɵtemplate(2, Chips_li_2_ng_container_2_Template, 1, 0, "ng-container", 9);
    i0.ɵɵtemplate(3, Chips_li_2_span_3_Template, 2, 1, "span", 10);
    i0.ɵɵtemplate(4, Chips_li_2_ng_container_4_Template, 3, 2, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(4, _c1, item_r3));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.itemTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.disabled);
  }
}
function Chips_li_6_TimesIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r25 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "TimesIcon", 14);
    i0.ɵɵlistener("click", function Chips_li_6_TimesIcon_1_Template_TimesIcon_click_0_listener() {
      i0.ɵɵrestoreView(_r25);
      const ctx_r24 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r24.clear());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-chips-clear-icon");
  }
}
function Chips_li_6_span_2_1_ng_template_0_Template(rf, ctx) {}
function Chips_li_6_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Chips_li_6_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Chips_li_6_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r29 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 18);
    i0.ɵɵlistener("click", function Chips_li_6_span_2_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r29);
      const ctx_r28 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r28.clear());
    });
    i0.ɵɵtemplate(1, Chips_li_6_span_2_1_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r23 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r23.clearIconTemplate);
  }
}
function Chips_li_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵtemplate(1, Chips_li_6_TimesIcon_1_Template, 1, 1, "TimesIcon", 12);
    i0.ɵɵtemplate(2, Chips_li_6_span_2_Template, 2, 1, "span", 17);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r2.clearIconTemplate);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.clearIconTemplate);
  }
}
const _c2 = function (a1, a2) {
  return {
    "p-inputtext p-chips-multiple-container": true,
    "p-focus": a1,
    "p-disabled": a2
  };
};
const _c3 = function (a0) {
  return {
    "p-chips-clearable": a0
  };
};
const CHIPS_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Chips),
  multi: true
};
/**
 * Chips groups a collection of contents in tabs.
 * @group Components
 */
class Chips {
  document;
  el;
  cd;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Style class of the element.
   * @group Props
   */
  styleClass;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  disabled;
  /**
   * Name of the property to display on a chip.
   * @group Props
   */
  field;
  /**
   * Advisory information to display on input.
   * @group Props
   */
  placeholder;
  /**
   * Maximum number of entries allowed.
   * @group Props
   */
  max;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex;
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  inputId;
  /**
   * Whether to allow duplicate values or not.
   * @group Props
   */
  allowDuplicate = true;
  /**
   * Inline style of the input field.
   * @group Props
   */
  inputStyle;
  /**
   * Style class of the input field.
   * @group Props
   */
  inputStyleClass;
  /**
   * Whether to add an item on tab key press.
   * @group Props
   */
  addOnTab;
  /**
   * Whether to add an item when the input loses focus.
   * @group Props
   */
  addOnBlur;
  /**
   * Separator char to add an item when pressed in addition to the enter key.
   * @group Props
   */
  separator;
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  showClear = false;
  /**
   * Callback to invoke on chip add.
   * @param {ChipsAddEvent} event - Custom chip add event.
   * @group Emits
   */
  onAdd = new EventEmitter();
  /**
   * Callback to invoke on chip remove.
   * @param {ChipsRemoveEvent} event - Custom chip remove event.
   * @group Emits
   */
  onRemove = new EventEmitter();
  /**
   * Callback to invoke on focus of input field.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke on blur of input field.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  /**
   * Callback to invoke on chip clicked.
   * @param {ChipsClickEvent} event - Custom chip click event.
   * @group Emits
   */
  onChipClick = new EventEmitter();
  /**
   * Callback to invoke on clear token clicked.
   * @group Emits
   */
  onClear = new EventEmitter();
  inputViewChild;
  templates;
  itemTemplate;
  removeTokenIconTemplate;
  clearIconTemplate;
  value;
  onModelChange = () => {};
  onModelTouched = () => {};
  valueChanged;
  focus;
  filled;
  constructor(document, el, cd) {
    this.document = document;
    this.el = el;
    this.cd = cd;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'removetokenicon':
          this.removeTokenIconTemplate = item.template;
          break;
        case 'clearicon':
          this.clearIconTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
    this.updateFilledState();
  }
  onClick() {
    this.inputViewChild?.nativeElement.focus();
  }
  onInput() {
    this.updateFilledState();
  }
  onPaste(event) {
    if (!this.disabled) {
      if (this.separator) {
        let pastedData = (event.clipboardData || this.document.defaultView['clipboardData']).getData('Text');
        pastedData.split(this.separator).forEach(val => {
          this.addItem(event, val, true);
        });
        this.inputViewChild.nativeElement.value = '';
      }
      this.updateFilledState();
    }
  }
  updateFilledState() {
    if (!this.value || this.value.length === 0) {
      this.filled = this.inputViewChild && this.inputViewChild.nativeElement && this.inputViewChild.nativeElement.value != '';
    } else {
      this.filled = true;
    }
  }
  onItemClick(event, item) {
    this.onChipClick.emit({
      originalEvent: event,
      value: item
    });
  }
  writeValue(value) {
    this.value = value;
    this.updateMaxedOut();
    this.updateFilledState();
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  resolveFieldData(data, field) {
    if (data && field) {
      if (field.indexOf('.') == -1) {
        return data[field];
      } else {
        let fields = field.split('.');
        let value = data;
        for (var i = 0, len = fields.length; i < len; ++i) {
          value = value[fields[i]];
        }
        return value;
      }
    } else {
      return null;
    }
  }
  onInputFocus(event) {
    this.focus = true;
    this.onFocus.emit(event);
  }
  onInputBlur(event) {
    this.focus = false;
    if (this.addOnBlur && this.inputViewChild.nativeElement.value) {
      this.addItem(event, this.inputViewChild.nativeElement.value, false);
    }
    this.onModelTouched();
    this.onBlur.emit(event);
  }
  removeItem(event, index) {
    if (this.disabled) {
      return;
    }
    let removedItem = this.value[index];
    this.value = this.value.filter((val, i) => i != index);
    this.onModelChange(this.value);
    this.onRemove.emit({
      originalEvent: event,
      value: removedItem
    });
    this.updateFilledState();
    this.updateMaxedOut();
  }
  addItem(event, item, preventDefault) {
    this.value = this.value || [];
    if (item && item.trim().length) {
      if (this.allowDuplicate || this.value.indexOf(item) === -1) {
        this.value = [...this.value, item];
        this.onModelChange(this.value);
        this.onAdd.emit({
          originalEvent: event,
          value: item
        });
      }
    }
    this.updateFilledState();
    this.updateMaxedOut();
    this.inputViewChild.nativeElement.value = '';
    if (preventDefault) {
      event.preventDefault();
    }
  }
  clear() {
    this.value = null;
    this.updateFilledState();
    this.onModelChange(this.value);
    this.onClear.emit();
  }
  onKeydown(event) {
    switch (event.which) {
      //backspace
      case 8:
        if (this.inputViewChild.nativeElement.value.length === 0 && this.value && this.value.length > 0) {
          this.value = [...this.value];
          let removedItem = this.value.pop();
          this.onModelChange(this.value);
          this.onRemove.emit({
            originalEvent: event,
            value: removedItem
          });
          this.updateFilledState();
        }
        break;
      //enter
      case 13:
        this.addItem(event, this.inputViewChild.nativeElement.value, true);
        break;
      case 9:
        if (this.addOnTab && this.inputViewChild.nativeElement.value !== '') {
          this.addItem(event, this.inputViewChild.nativeElement.value, true);
        }
        break;
      default:
        if (this.max && this.value && this.max === this.value.length) {
          event.preventDefault();
        } else if (this.separator) {
          if (this.separator === event.key || event.key.match(this.separator)) {
            this.addItem(event, this.inputViewChild.nativeElement.value, true);
          }
        }
        break;
    }
  }
  updateMaxedOut() {
    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      if (this.max && this.value && this.max === this.value.length) {
        // Calling `blur` is necessary because firefox does not call `onfocus` events
        // for disabled inputs, unlike chromium browsers.
        this.inputViewChild.nativeElement.blur();
        this.inputViewChild.nativeElement.disabled = true;
      } else {
        if (this.disabled) {
          this.inputViewChild.nativeElement.blur();
        }
        this.inputViewChild.nativeElement.disabled = this.disabled || false;
      }
    }
  }
  static ɵfac = function Chips_Factory(t) {
    return new (t || Chips)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Chips,
    selectors: [["p-chips"]],
    contentQueries: function Chips_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Chips_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputViewChild = _t.first);
      }
    },
    hostAttrs: [1, "p-element", "p-inputwrapper"],
    hostVars: 6,
    hostBindings: function Chips_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("p-inputwrapper-filled", ctx.filled)("p-inputwrapper-focus", ctx.focus)("p-chips-clearable", ctx.showClear);
      }
    },
    inputs: {
      style: "style",
      styleClass: "styleClass",
      disabled: "disabled",
      field: "field",
      placeholder: "placeholder",
      max: "max",
      ariaLabelledBy: "ariaLabelledBy",
      tabindex: "tabindex",
      inputId: "inputId",
      allowDuplicate: "allowDuplicate",
      inputStyle: "inputStyle",
      inputStyleClass: "inputStyleClass",
      addOnTab: "addOnTab",
      addOnBlur: "addOnBlur",
      separator: "separator",
      showClear: "showClear"
    },
    outputs: {
      onAdd: "onAdd",
      onRemove: "onRemove",
      onFocus: "onFocus",
      onBlur: "onBlur",
      onChipClick: "onChipClick",
      onClear: "onClear"
    },
    features: [i0.ɵɵProvidersFeature([CHIPS_VALUE_ACCESSOR])],
    decls: 7,
    vars: 21,
    consts: [[3, "ngClass", "ngStyle", "click"], [3, "ngClass"], ["class", "p-chips-token", 3, "click", 4, "ngFor", "ngForOf"], [1, "p-chips-input-token", 3, "ngClass"], ["type", "text", 3, "disabled", "ngStyle", "keydown", "input", "paste", "focus", "blur"], ["inputtext", ""], [4, "ngIf"], [1, "p-chips-token", 3, "click"], ["token", ""], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "p-chips-token-label", 4, "ngIf"], [1, "p-chips-token-label"], [3, "styleClass", "click", 4, "ngIf"], ["class", "p-chips-token-icon", 3, "click", 4, "ngIf"], [3, "styleClass", "click"], [1, "p-chips-token-icon", 3, "click"], [4, "ngTemplateOutlet"], ["class", "p-chips-clear-icon", 3, "click", 4, "ngIf"], [1, "p-chips-clear-icon", 3, "click"]],
    template: function Chips_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function Chips_Template_div_click_0_listener() {
          return ctx.onClick();
        });
        i0.ɵɵelementStart(1, "ul", 1);
        i0.ɵɵtemplate(2, Chips_li_2_Template, 5, 6, "li", 2);
        i0.ɵɵelementStart(3, "li", 3)(4, "input", 4, 5);
        i0.ɵɵlistener("keydown", function Chips_Template_input_keydown_4_listener($event) {
          return ctx.onKeydown($event);
        })("input", function Chips_Template_input_input_4_listener() {
          return ctx.onInput();
        })("paste", function Chips_Template_input_paste_4_listener($event) {
          return ctx.onPaste($event);
        })("focus", function Chips_Template_input_focus_4_listener($event) {
          return ctx.onInputFocus($event);
        })("blur", function Chips_Template_input_blur_4_listener($event) {
          return ctx.onInputBlur($event);
        });
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(6, Chips_li_6_Template, 3, 2, "li", 6);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", "p-chips p-component")("ngStyle", ctx.style);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(16, _c2, ctx.focus, ctx.disabled));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.value);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(19, _c3, ctx.showClear && !ctx.disabled));
        i0.ɵɵadvance(1);
        i0.ɵɵclassMap(ctx.inputStyleClass);
        i0.ɵɵproperty("disabled", ctx.disabled)("ngStyle", ctx.inputStyle);
        i0.ɵɵattribute("id", ctx.inputId)("placeholder", ctx.value && ctx.value.length ? null : ctx.placeholder)("tabindex", ctx.tabindex)("aria-labelledby", ctx.ariaLabelledBy);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.value != null && ctx.filled && !ctx.disabled && ctx.showClear);
      }
    },
    dependencies: function () {
      return [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, TimesCircleIcon, TimesIcon];
    },
    styles: [".p-chips{display:inline-flex}.p-chips-multiple-container{margin:0;padding:0;list-style-type:none;cursor:text;overflow:hidden;display:flex;align-items:center;flex-wrap:wrap}.p-chips-token{cursor:default;display:inline-flex;align-items:center;flex:0 0 auto;max-width:100%}.p-chips-token-label{min-width:0%;overflow:auto}.p-chips-token-label::-webkit-scrollbar{display:none}.p-chips-input-token{flex:1 1 auto;display:inline-flex}.p-chips-token-icon{cursor:pointer}.p-chips-input-token input{border:0 none;outline:0 none;background-color:transparent;margin:0;padding:0;box-shadow:none;border-radius:0;width:100%}.p-fluid .p-chips{display:flex}.p-chips-clear-icon{position:absolute;top:50%;margin-top:-.5rem;cursor:pointer}.p-chips-clearable .p-inputtext{position:relative}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Chips, [{
    type: Component,
    args: [{
      selector: 'p-chips',
      template: `
        <div [ngClass]="'p-chips p-component'" [ngStyle]="style" [class]="styleClass" (click)="onClick()">
            <ul [ngClass]="{ 'p-inputtext p-chips-multiple-container': true, 'p-focus': focus, 'p-disabled': disabled }">
                <li #token *ngFor="let item of value; let i = index" class="p-chips-token" (click)="onItemClick($event, item)">
                    <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                    <span *ngIf="!itemTemplate" class="p-chips-token-label">{{ field ? resolveFieldData(item, field) : item }}</span>
                    <ng-container *ngIf="!disabled">
                        <TimesCircleIcon [styleClass]="'p-chips-token-icon'" *ngIf="!removeTokenIconTemplate" (click)="removeItem($event, i)" />
                        <span *ngIf="removeTokenIconTemplate" class="p-chips-token-icon" (click)="removeItem($event, i)">
                            <ng-template *ngTemplateOutlet="removeTokenIconTemplate"></ng-template>
                        </span>
                    </ng-container>
                </li>
                <li class="p-chips-input-token" [ngClass]="{ 'p-chips-clearable': showClear && !disabled }">
                    <input
                        #inputtext
                        type="text"
                        [attr.id]="inputId"
                        [attr.placeholder]="value && value.length ? null : placeholder"
                        [attr.tabindex]="tabindex"
                        (keydown)="onKeydown($event)"
                        (input)="onInput()"
                        (paste)="onPaste($event)"
                        [attr.aria-labelledby]="ariaLabelledBy"
                        (focus)="onInputFocus($event)"
                        (blur)="onInputBlur($event)"
                        [disabled]="disabled"
                        [ngStyle]="inputStyle"
                        [class]="inputStyleClass"
                    />
                </li>
                <li *ngIf="value != null && filled && !disabled && showClear">
                    <TimesIcon *ngIf="!clearIconTemplate" [styleClass]="'p-chips-clear-icon'" (click)="clear()" />
                    <span *ngIf="clearIconTemplate" class="p-chips-clear-icon" (click)="clear()">
                        <ng-template *ngTemplateOutlet="clearIconTemplate"></ng-template>
                    </span>
                </li>
            </ul>
        </div>
    `,
      host: {
        class: 'p-element p-inputwrapper',
        '[class.p-inputwrapper-filled]': 'filled',
        '[class.p-inputwrapper-focus]': 'focus',
        '[class.p-chips-clearable]': 'showClear'
      },
      providers: [CHIPS_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      styles: [".p-chips{display:inline-flex}.p-chips-multiple-container{margin:0;padding:0;list-style-type:none;cursor:text;overflow:hidden;display:flex;align-items:center;flex-wrap:wrap}.p-chips-token{cursor:default;display:inline-flex;align-items:center;flex:0 0 auto;max-width:100%}.p-chips-token-label{min-width:0%;overflow:auto}.p-chips-token-label::-webkit-scrollbar{display:none}.p-chips-input-token{flex:1 1 auto;display:inline-flex}.p-chips-token-icon{cursor:pointer}.p-chips-input-token input{border:0 none;outline:0 none;background-color:transparent;margin:0;padding:0;box-shadow:none;border-radius:0;width:100%}.p-fluid .p-chips{display:flex}.p-chips-clear-icon{position:absolute;top:50%;margin-top:-.5rem;cursor:pointer}.p-chips-clearable .p-inputtext{position:relative}\n"]
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    field: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    allowDuplicate: [{
      type: Input
    }],
    inputStyle: [{
      type: Input
    }],
    inputStyleClass: [{
      type: Input
    }],
    addOnTab: [{
      type: Input
    }],
    addOnBlur: [{
      type: Input
    }],
    separator: [{
      type: Input
    }],
    showClear: [{
      type: Input
    }],
    onAdd: [{
      type: Output
    }],
    onRemove: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    onChipClick: [{
      type: Output
    }],
    onClear: [{
      type: Output
    }],
    inputViewChild: [{
      type: ViewChild,
      args: ['inputtext']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class ChipsModule {
  static ɵfac = function ChipsModule_Factory(t) {
    return new (t || ChipsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ChipsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, InputTextModule, SharedModule, TimesCircleIcon, TimesIcon, InputTextModule, SharedModule]
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, InputTextModule, SharedModule, TimesCircleIcon, TimesIcon],
      exports: [Chips, InputTextModule, SharedModule],
      declarations: [Chips]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CHIPS_VALUE_ACCESSOR, Chips, ChipsModule };
