import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule as PDropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ChipsModule } from 'primeng/chips';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import { HeaderComponent } from 'src/app/components/header/header.component';
import { CamelToSentencePipeModule, DropdownModule } from '@wilson/wilsonng';
import { DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';

@NgModule({
  declarations: [
    HeaderComponent,

    // directives
  ],
  imports: [
    // angular
    CommonModule,
    RouterModule,

    // primeng
    PDropdownModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    TableModule,
    CheckboxModule,
    TabViewModule,
    TooltipModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    InputSwitchModule,
    SplitButtonModule,
    ButtonModule,
    ChipsModule,
    ScrollPanelModule,

    // devexpress
    DxDataGridModule,
    DxCheckBoxModule,

    // wilsonng
    DropdownModule,
    CamelToSentencePipeModule,
  ],
  exports: [
    // primeng
    PDropdownModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    CheckboxModule,
    TabViewModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    InputSwitchModule,
    SplitButtonModule,
    ButtonModule,
    ChipsModule,
    ScrollPanelModule,

    // devexpress
    DxDataGridModule,
    DxCheckBoxModule,

    // wilsonng
    TableModule,
    TooltipModule,
    DropdownModule,
    CamelToSentencePipeModule,

    // app components
    HeaderComponent,

    // directives
  ],
})
export class UIModule {}
