import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
class RedirectService {
  constructor() {
    this.redirectCookieName = 'wilsonRedirect';
  }
  setRedirectCookie(query = null, customPath = '') {
    // Set expiration to 1 hour
    const now = new Date();
    let time = now.getTime();
    time += 3600 * 1000;
    now.setTime(time);
    let path = customPath || window.location.pathname;
    let urlString = `${this.redirectCookieName}=${path}`;
    if (query) {
      urlString += `?${query}`;
    }
    document.cookie = `${urlString}; path=/; expires=${now.toUTCString()} `;
  }
  clearRedirectCookie() {
    document.cookie = `${this.redirectCookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  getRedirectCookie() {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${this.redirectCookieName}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
  setRedirectCookieName(name) {
    this.redirectCookieName = name;
  }
  static {
    this.ɵfac = function RedirectService_Factory(t) {
      return new (t || RedirectService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RedirectService,
      factory: RedirectService.ɵfac,
      providedIn: 'root'
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RedirectService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RedirectService };
