<header #header class="site-header" [class.admin-funnel]="isAdmin">
  <div class="container">
    <nav class="nav-left">
      <div class="wlt-title">
        <div class="primary">{{ primaryTitle }}</div>
        <div class="secondary" *ngIf="secondaryTitle">{{ secondaryTitle }}</div>
      </div>
      <ng-container *ngIf="isAdmin">
        <ul class="nav-links hide-xl">
          <li *ngFor="let link of pageLinks">
            <a
              [routerLink]="link.path"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              {{ link.text }}
            </a>
          </li>
        </ul>
      </ng-container>
    </nav>
    <div class="nav-right">
      <button
        (click)="userMenu.toggle($event)"
        class="wlt-btn user-menu icon-right"
        *ngIf="userInitials"
      >
        <span class="wlt-avatar">
          {{ userInitials }}
        </span>
        <i class="fas fa-caret-down" aria-hidden="true"></i>
      </button>
      <p-menu
        #userMenu
        [popup]="true"
        [model]="menuItems"
        [appendTo]="header"
        styleClass="user-menu-container soft-shadow"
      ></p-menu>
    </div>
  </div>
</header>
