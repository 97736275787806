import * as i0 from '@angular/core';
import { PLATFORM_ID, Pipe, Inject, forwardRef, Component, ViewEncapsulation, Input, EventEmitter, ChangeDetectionStrategy, Output, NgModule } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import { DomHandler, ConnectedOverlayScrollHandler } from 'primeng/dom';
import { ZIndexUtils } from 'primeng/utils';
import * as i3 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i4 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import * as i6 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import * as i1 from '@angular/platform-browser';
import * as i5 from 'primeng/api';
const _c0 = ["pMenuItemContent", ""];
function MenuItemContent_a_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c1 = function (a0) {
  return {
    "p-disabled": a0
  };
};
function MenuItemContent_a_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 3);
    i0.ɵɵlistener("keydown", function MenuItemContent_a_0_Template_a_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onItemKeyDown($event));
    })("click", function MenuItemContent_a_0_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.menu.itemClick($event, ctx_r7.item));
    });
    i0.ɵɵtemplate(1, MenuItemContent_a_0_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const _r2 = i0.ɵɵreference(3);
    i0.ɵɵproperty("target", ctx_r0.item.target)("ngClass", i0.ɵɵpureFunction1(8, _c1, ctx_r0.item.disabled));
    i0.ɵɵattribute("href", ctx_r0.item.url || null, i0.ɵɵsanitizeUrl)("tabindex", ctx_r0.item.disabled ? null : "0")("data-automationid", ctx_r0.item.automationId)("title", ctx_r0.item.title)("id", ctx_r0.item.id);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", _r2);
  }
}
function MenuItemContent_a_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c2 = function () {
  return {
    exact: false
  };
};
function MenuItemContent_a_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 5);
    i0.ɵɵlistener("keydown", function MenuItemContent_a_1_Template_a_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.onItemKeyDown($event));
    })("click", function MenuItemContent_a_1_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.menu.itemClick($event, ctx_r11.item));
    });
    i0.ɵɵtemplate(1, MenuItemContent_a_1_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const _r2 = i0.ɵɵreference(3);
    i0.ɵɵproperty("routerLink", ctx_r1.item.routerLink)("queryParams", ctx_r1.item.queryParams)("routerLinkActiveOptions", ctx_r1.item.routerLinkActiveOptions || i0.ɵɵpureFunction0(16, _c2))("target", ctx_r1.item.target)("ngClass", i0.ɵɵpureFunction1(17, _c1, ctx_r1.item.disabled))("fragment", ctx_r1.item.fragment)("queryParamsHandling", ctx_r1.item.queryParamsHandling)("preserveFragment", ctx_r1.item.preserveFragment)("skipLocationChange", ctx_r1.item.skipLocationChange)("replaceUrl", ctx_r1.item.replaceUrl)("state", ctx_r1.item.state);
    i0.ɵɵattribute("data-automationid", ctx_r1.item.automationId)("id", ctx_r1.item.id)("tabindex", ctx_r1.item.disabled ? null : "0")("title", ctx_r1.item.title);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", _r2);
  }
}
function MenuItemContent_ng_template_2_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 10);
  }
  if (rf & 2) {
    const ctx_r12 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r12.item.iconClass);
    i0.ɵɵproperty("ngClass", ctx_r12.item.icon)("ngStyle", ctx_r12.item.iconStyle);
  }
}
function MenuItemContent_ng_template_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r13 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r13.item.label);
  }
}
function MenuItemContent_ng_template_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 12);
    i0.ɵɵpipe(1, "safeHtml");
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(1, 1, ctx_r15.item.label), i0.ɵɵsanitizeHtml);
  }
}
function MenuItemContent_ng_template_2_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r16 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r16.item.badgeStyleClass);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r16.item.badge);
  }
}
function MenuItemContent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MenuItemContent_ng_template_2_span_0_Template, 1, 4, "span", 6);
    i0.ɵɵtemplate(1, MenuItemContent_ng_template_2_span_1_Template, 2, 1, "span", 7);
    i0.ɵɵtemplate(2, MenuItemContent_ng_template_2_ng_template_2_Template, 2, 3, "ng-template", null, 8, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵtemplate(4, MenuItemContent_ng_template_2_span_4_Template, 2, 2, "span", 9);
  }
  if (rf & 2) {
    const _r14 = i0.ɵɵreference(3);
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r3.item.icon);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.item.escape !== false)("ngIfElse", _r14);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r3.item.badge);
  }
}
const _c3 = function (a0) {
  return {
    "p-hidden": a0
  };
};
function Menu_div_0_3_ng_template_0_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 8);
  }
  if (rf & 2) {
    const submenu_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c3, submenu_r5.visible === false));
  }
}
function Menu_div_0_3_ng_template_0_li_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const submenu_r5 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(submenu_r5.label);
  }
}
function Menu_div_0_3_ng_template_0_li_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 12);
    i0.ɵɵpipe(1, "safeHtml");
  }
  if (rf & 2) {
    const submenu_r5 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(1, 1, submenu_r5.label), i0.ɵɵsanitizeHtml);
  }
}
const _c4 = function (a0, a1) {
  return {
    "p-hidden": a0,
    flex: a1
  };
};
function Menu_div_0_3_ng_template_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 9);
    i0.ɵɵtemplate(1, Menu_div_0_3_ng_template_0_li_1_span_1_Template, 2, 1, "span", 10);
    i0.ɵɵtemplate(2, Menu_div_0_3_ng_template_0_li_1_ng_template_2_Template, 2, 3, "ng-template", null, 11, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r11 = i0.ɵɵreference(3);
    const submenu_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(5, _c4, submenu_r5.visible === false, submenu_r5.visible))("tooltipOptions", submenu_r5.tooltipOptions);
    i0.ɵɵattribute("data-automationid", submenu_r5.automationId);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", submenu_r5.escape !== false)("ngIfElse", _r11);
  }
}
function Menu_div_0_3_ng_template_0_ng_template_2_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 8);
  }
  if (rf & 2) {
    const item_r16 = i0.ɵɵnextContext().$implicit;
    const submenu_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c3, item_r16.visible === false || submenu_r5.visible === false));
  }
}
function Menu_div_0_3_ng_template_0_ng_template_2_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 14);
  }
  if (rf & 2) {
    const item_r16 = i0.ɵɵnextContext().$implicit;
    const submenu_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵclassMap(item_r16.styleClass);
    i0.ɵɵproperty("pMenuItemContent", item_r16)("ngClass", i0.ɵɵpureFunction1(6, _c3, item_r16.visible === false || submenu_r5.visible === false))("ngStyle", item_r16.style)("tooltipOptions", item_r16.tooltipOptions);
  }
}
function Menu_div_0_3_ng_template_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_3_ng_template_0_ng_template_2_li_0_Template, 1, 3, "li", 6);
    i0.ɵɵtemplate(1, Menu_div_0_3_ng_template_0_ng_template_2_li_1_Template, 1, 8, "li", 13);
  }
  if (rf & 2) {
    const item_r16 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", item_r16.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !item_r16.separator);
  }
}
function Menu_div_0_3_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_3_ng_template_0_li_0_Template, 1, 3, "li", 6);
    i0.ɵɵtemplate(1, Menu_div_0_3_ng_template_0_li_1_Template, 4, 8, "li", 7);
    i0.ɵɵtemplate(2, Menu_div_0_3_ng_template_0_ng_template_2_Template, 2, 2, "ng-template", 5);
  }
  if (rf & 2) {
    const submenu_r5 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", submenu_r5.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !submenu_r5.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", submenu_r5.items);
  }
}
function Menu_div_0_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_3_ng_template_0_Template, 3, 3, "ng-template", 5);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngForOf", ctx_r2.model);
  }
}
function Menu_div_0_4_ng_template_0_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 8);
  }
  if (rf & 2) {
    const item_r24 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c3, item_r24.visible === false));
  }
}
function Menu_div_0_4_ng_template_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "li", 14);
  }
  if (rf & 2) {
    const item_r24 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵclassMap(item_r24.styleClass);
    i0.ɵɵproperty("pMenuItemContent", item_r24)("ngClass", i0.ɵɵpureFunction1(6, _c3, item_r24.visible === false))("ngStyle", item_r24.style)("tooltipOptions", item_r24.tooltipOptions);
  }
}
function Menu_div_0_4_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_4_ng_template_0_li_0_Template, 1, 3, "li", 6);
    i0.ɵɵtemplate(1, Menu_div_0_4_ng_template_0_li_1_Template, 1, 8, "li", 13);
  }
  if (rf & 2) {
    const item_r24 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", item_r24.separator);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !item_r24.separator);
  }
}
function Menu_div_0_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Menu_div_0_4_ng_template_0_Template, 2, 2, "ng-template", 5);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngForOf", ctx_r3.model);
  }
}
const _c5 = function (a1) {
  return {
    "p-menu p-component": true,
    "p-menu-overlay": a1
  };
};
const _c6 = function (a0, a1) {
  return {
    showTransitionParams: a0,
    hideTransitionParams: a1
  };
};
const _c7 = function (a1) {
  return {
    value: "visible",
    params: a1
  };
};
function Menu_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r30 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1, 2);
    i0.ɵɵlistener("click", function Menu_div_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r30);
      const ctx_r29 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r29.onOverlayClick($event));
    })("@overlayAnimation.start", function Menu_div_0_Template_div_animation_overlayAnimation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r30);
      const ctx_r31 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r31.onOverlayAnimationStart($event));
    })("@overlayAnimation.done", function Menu_div_0_Template_div_animation_overlayAnimation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r30);
      const ctx_r32 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r32.onOverlayAnimationEnd($event));
    });
    i0.ɵɵelementStart(2, "ul", 3);
    i0.ɵɵtemplate(3, Menu_div_0_3_Template, 1, 1, null, 4);
    i0.ɵɵtemplate(4, Menu_div_0_4_Template, 1, 1, null, 4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c5, ctx_r0.popup))("ngStyle", ctx_r0.style)("@overlayAnimation", i0.ɵɵpureFunction1(13, _c7, i0.ɵɵpureFunction2(10, _c6, ctx_r0.showTransitionOptions, ctx_r0.hideTransitionOptions)))("@.disabled", ctx_r0.popup !== true);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.hasSubMenu());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.hasSubMenu());
  }
}
class SafeHtmlPipe {
  platformId;
  sanitizer;
  constructor(platformId, sanitizer) {
    this.platformId = platformId;
    this.sanitizer = sanitizer;
  }
  transform(value) {
    if (!value || !isPlatformBrowser(this.platformId)) {
      return value;
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  static ɵfac = function SafeHtmlPipe_Factory(t) {
    return new (t || SafeHtmlPipe)(i0.ɵɵdirectiveInject(PLATFORM_ID, 16), i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "safeHtml",
    type: SafeHtmlPipe,
    pure: true
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafeHtmlPipe, [{
    type: Pipe,
    args: [{
      name: 'safeHtml'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i1.DomSanitizer
    }];
  }, null);
})();
class MenuItemContent {
  item;
  menu;
  constructor(menu) {
    this.menu = menu;
  }
  onItemKeyDown(event) {
    let listItem = event.currentTarget.parentElement;
    switch (event.code) {
      case 'ArrowDown':
        var nextItem = this.findNextItem(listItem);
        if (nextItem) {
          nextItem.children[0].focus();
        }
        event.preventDefault();
        break;
      case 'ArrowUp':
        var prevItem = this.findPrevItem(listItem);
        if (prevItem) {
          prevItem.children[0].focus();
        }
        event.preventDefault();
        break;
      case 'Space':
      case 'Enter':
        if (listItem && !DomHandler.hasClass(listItem, 'p-disabled')) {
          listItem.children[0].click();
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  }
  findNextItem(item) {
    let nextItem = item.nextElementSibling;
    if (nextItem) return DomHandler.hasClass(nextItem, 'p-disabled') || !DomHandler.hasClass(nextItem, 'p-menuitem') ? this.findNextItem(nextItem) : nextItem;else return null;
  }
  findPrevItem(item) {
    let prevItem = item.previousElementSibling;
    if (prevItem) return DomHandler.hasClass(prevItem, 'p-disabled') || !DomHandler.hasClass(prevItem, 'p-menuitem') ? this.findPrevItem(prevItem) : prevItem;else return null;
  }
  static ɵfac = function MenuItemContent_Factory(t) {
    return new (t || MenuItemContent)(i0.ɵɵdirectiveInject(forwardRef(() => Menu)));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: MenuItemContent,
    selectors: [["", "pMenuItemContent", ""]],
    hostAttrs: [1, "p-element"],
    inputs: {
      item: ["pMenuItemContent", "item"]
    },
    attrs: _c0,
    decls: 4,
    vars: 2,
    consts: [["class", "p-menuitem-link", "role", "menuitem", 3, "target", "ngClass", "keydown", "click", 4, "ngIf"], ["routerLinkActive", "p-menuitem-link-active", "class", "p-menuitem-link", "role", "menuitem", "pRipple", "", 3, "routerLink", "queryParams", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "keydown", "click", 4, "ngIf"], ["itemContent", ""], ["role", "menuitem", 1, "p-menuitem-link", 3, "target", "ngClass", "keydown", "click"], [4, "ngTemplateOutlet"], ["routerLinkActive", "p-menuitem-link-active", "role", "menuitem", "pRipple", "", 1, "p-menuitem-link", 3, "routerLink", "queryParams", "routerLinkActiveOptions", "target", "ngClass", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "keydown", "click"], ["class", "p-menuitem-icon", 3, "ngClass", "class", "ngStyle", 4, "ngIf"], ["class", "p-menuitem-text", 4, "ngIf", "ngIfElse"], ["htmlLabel", ""], ["class", "p-menuitem-badge", 3, "ngClass", 4, "ngIf"], [1, "p-menuitem-icon", 3, "ngClass", "ngStyle"], [1, "p-menuitem-text"], [1, "p-menuitem-text", 3, "innerHTML"], [1, "p-menuitem-badge", 3, "ngClass"]],
    template: function MenuItemContent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, MenuItemContent_a_0_Template, 2, 10, "a", 0);
        i0.ɵɵtemplate(1, MenuItemContent_a_1_Template, 2, 19, "a", 1);
        i0.ɵɵtemplate(2, MenuItemContent_ng_template_2_Template, 5, 4, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", !(ctx.item == null ? null : ctx.item.routerLink));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.item == null ? null : ctx.item.routerLink);
      }
    },
    dependencies: [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i3.RouterLink, i3.RouterLinkActive, i4.Ripple, SafeHtmlPipe],
    encapsulation: 2
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemContent, [{
    type: Component,
    args: [{
      selector: '[pMenuItemContent]',
      template: `
        <a
            *ngIf="!item?.routerLink"
            (keydown)="onItemKeyDown($event)"
            [attr.href]="item.url || null"
            class="p-menuitem-link"
            [attr.tabindex]="item.disabled ? null : '0'"
            [attr.data-automationid]="item.automationId"
            [target]="item.target"
            [attr.title]="item.title"
            [attr.id]="item.id"
            [ngClass]="{ 'p-disabled': item.disabled }"
            (click)="menu.itemClick($event, item)"
            role="menuitem"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>
        <a
            *ngIf="item?.routerLink"
            (keydown)="onItemKeyDown($event)"
            [routerLink]="item.routerLink"
            [attr.data-automationid]="item.automationId"
            [queryParams]="item.queryParams"
            routerLinkActive="p-menuitem-link-active"
            [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
            class="p-menuitem-link"
            [target]="item.target"
            [attr.id]="item.id"
            [attr.tabindex]="item.disabled ? null : '0'"
            [attr.title]="item.title"
            [ngClass]="{ 'p-disabled': item.disabled }"
            (click)="menu.itemClick($event, item)"
            role="menuitem"
            pRipple
            [fragment]="item.fragment"
            [queryParamsHandling]="item.queryParamsHandling"
            [preserveFragment]="item.preserveFragment"
            [skipLocationChange]="item.skipLocationChange"
            [replaceUrl]="item.replaceUrl"
            [state]="item.state"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <ng-template #itemContent>
            <span class="p-menuitem-icon" *ngIf="item.icon" [ngClass]="item.icon" [class]="item.iconClass" [ngStyle]="item.iconStyle"></span>
            <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlLabel">{{ item.label }}</span>
            <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="item.label | safeHtml"></span></ng-template>
            <span class="p-menuitem-badge" *ngIf="item.badge" [ngClass]="item.badgeStyleClass">{{ item.badge }}</span>
        </ng-template>
    `,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: Menu,
      decorators: [{
        type: Inject,
        args: [forwardRef(() => Menu)]
      }]
    }];
  }, {
    item: [{
      type: Input,
      args: ['pMenuItemContent']
    }]
  });
})();
/**
 * Menu is a navigation / command component that supports dynamic and static positioning.
 * @group Components
 */
class Menu {
  document;
  platformId;
  el;
  renderer;
  cd;
  config;
  overlayService;
  /**
   * An array of menuitems.
   * @group Props
   */
  model;
  /**
   * Defines if menu would displayed as a popup.
   * @group Props
   */
  popup;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex = 0;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  hideTransitionOptions = '.1s linear';
  /**
   * Callback to invoke when overlay menu is shown.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Callback to invoke when overlay menu is hidden.
   * @group Emits
   */
  onHide = new EventEmitter();
  container;
  scrollHandler;
  documentClickListener;
  documentResizeListener;
  preventDocumentDefault;
  target;
  visible;
  relativeAlign;
  constructor(document, platformId, el, renderer, cd, config, overlayService) {
    this.document = document;
    this.platformId = platformId;
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.config = config;
    this.overlayService = overlayService;
  }
  /**
   * Toggles the visibility of the popup menu.
   * @param {Event} event - Browser event.
   * @group Method
   */
  toggle(event) {
    if (this.visible) this.hide();else this.show(event);
    this.preventDocumentDefault = true;
  }
  /**
   * Displays the popup menu.
   * @param {Event} event - Browser event.
   * @group Method
   */
  show(event) {
    this.target = event.currentTarget;
    this.relativeAlign = event.relativeAlign;
    this.visible = true;
    this.preventDocumentDefault = true;
    this.cd.markForCheck();
  }
  onOverlayAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        if (this.popup) {
          this.container = event.element;
          this.moveOnTop();
          this.onShow.emit({});
          this.appendOverlay();
          this.alignOverlay();
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
          this.bindScrollListener();
        }
        break;
      case 'void':
        this.onOverlayHide();
        this.onHide.emit({});
        break;
    }
  }
  onOverlayAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        if (this.autoZIndex) {
          ZIndexUtils.clear(event.element);
        }
        break;
    }
  }
  alignOverlay() {
    if (this.relativeAlign) DomHandler.relativePosition(this.container, this.target);else DomHandler.absolutePosition(this.container, this.target);
  }
  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.renderer.appendChild(this.document.body, this.container);else DomHandler.appendChild(this.container, this.appendTo);
    }
  }
  restoreOverlayAppend() {
    if (this.container && this.appendTo) {
      this.renderer.appendChild(this.el.nativeElement, this.container);
    }
  }
  moveOnTop() {
    if (this.autoZIndex) {
      ZIndexUtils.set('menu', this.container, this.baseZIndex + this.config.zIndex.menu);
    }
  }
  /**
   * Hides the popup menu.
   * @group Method
   */
  hide() {
    this.visible = false;
    this.relativeAlign = false;
    this.cd.markForCheck();
  }
  onWindowResize() {
    if (this.visible && !DomHandler.isTouchDevice()) {
      this.hide();
    }
  }
  itemClick(event, item) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }
    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item
      });
    }
    if (this.popup) {
      this.hide();
    }
  }
  onOverlayClick(event) {
    if (this.popup) {
      this.overlayService.add({
        originalEvent: event,
        target: this.el.nativeElement
      });
    }
    this.preventDocumentDefault = true;
  }
  bindDocumentClickListener() {
    if (!this.documentClickListener && isPlatformBrowser(this.platformId)) {
      const documentTarget = this.el ? this.el.nativeElement.ownerDocument : 'document';
      this.documentClickListener = this.renderer.listen(documentTarget, 'click', () => {
        if (!this.preventDocumentDefault) {
          this.hide();
        }
        this.preventDocumentDefault = false;
      });
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  bindDocumentResizeListener() {
    if (!this.documentResizeListener && isPlatformBrowser(this.platformId)) {
      const window = this.document.defaultView;
      this.documentResizeListener = this.renderer.listen(window, 'resize', this.onWindowResize.bind(this));
    }
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      this.documentResizeListener();
      this.documentResizeListener = null;
    }
  }
  bindScrollListener() {
    if (!this.scrollHandler && isPlatformBrowser(this.platformId)) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.target, () => {
        if (this.visible) {
          this.hide();
        }
      });
    }
    this.scrollHandler?.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
    this.preventDocumentDefault = false;
    if (!this.cd.destroyed) {
      this.target = null;
    }
  }
  ngOnDestroy() {
    if (this.popup) {
      if (this.scrollHandler) {
        this.scrollHandler.destroy();
        this.scrollHandler = null;
      }
      if (this.container && this.autoZIndex) {
        ZIndexUtils.clear(this.container);
      }
      this.restoreOverlayAppend();
      this.onOverlayHide();
    }
  }
  hasSubMenu() {
    if (this.model) {
      for (var item of this.model) {
        if (item.items) {
          return true;
        }
      }
    }
    return false;
  }
  static ɵfac = function Menu_Factory(t) {
    return new (t || Menu)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i5.PrimeNGConfig), i0.ɵɵdirectiveInject(i5.OverlayService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Menu,
    selectors: [["p-menu"]],
    hostAttrs: [1, "p-element"],
    inputs: {
      model: "model",
      popup: "popup",
      style: "style",
      styleClass: "styleClass",
      appendTo: "appendTo",
      autoZIndex: "autoZIndex",
      baseZIndex: "baseZIndex",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions"
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide"
    },
    decls: 1,
    vars: 1,
    consts: [[3, "ngClass", "class", "ngStyle", "click", 4, "ngIf"], [3, "ngClass", "ngStyle", "click"], ["container", ""], ["role", "menu", 1, "p-menu-list", "p-reset"], [4, "ngIf"], ["ngFor", "", 3, "ngForOf"], ["class", "p-menu-separator", "role", "separator", 3, "ngClass", 4, "ngIf"], ["class", "p-submenu-header", "pTooltip", "", "role", "none", 3, "ngClass", "tooltipOptions", 4, "ngIf"], ["role", "separator", 1, "p-menu-separator", 3, "ngClass"], ["pTooltip", "", "role", "none", 1, "p-submenu-header", 3, "ngClass", "tooltipOptions"], [4, "ngIf", "ngIfElse"], ["htmlSubmenuLabel", ""], [3, "innerHTML"], ["class", "p-menuitem", "pTooltip", "", "role", "none", 3, "pMenuItemContent", "ngClass", "ngStyle", "class", "tooltipOptions", 4, "ngIf"], ["pTooltip", "", "role", "none", 1, "p-menuitem", 3, "pMenuItemContent", "ngClass", "ngStyle", "tooltipOptions"]],
    template: function Menu_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, Menu_div_0_Template, 5, 15, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", !ctx.popup || ctx.visible);
      }
    },
    dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgStyle, i6.Tooltip, MenuItemContent, SafeHtmlPipe],
    styles: [".p-menu-overlay{position:absolute;top:0;left:0}.p-menu ul{margin:0;padding:0;list-style:none}.p-menu .p-submenu-header{align-items:center}.p-menu .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-menu .p-menuitem-text{line-height:1}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])]
    },
    changeDetection: 0
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Menu, [{
    type: Component,
    args: [{
      selector: 'p-menu',
      template: `
        <div
            #container
            [ngClass]="{ 'p-menu p-component': true, 'p-menu-overlay': popup }"
            [class]="styleClass"
            [ngStyle]="style"
            *ngIf="!popup || visible"
            (click)="onOverlayClick($event)"
            [@overlayAnimation]="{ value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
            [@.disabled]="popup !== true"
            (@overlayAnimation.start)="onOverlayAnimationStart($event)"
            (@overlayAnimation.done)="onOverlayAnimationEnd($event)"
        >
            <ul class="p-menu-list p-reset" role="menu">
                <ng-template ngFor let-submenu [ngForOf]="model" *ngIf="hasSubMenu()">
                    <li class="p-menu-separator" *ngIf="submenu.separator" [ngClass]="{ 'p-hidden': submenu.visible === false }" role="separator"></li>
                    <li
                        class="p-submenu-header"
                        [attr.data-automationid]="submenu.automationId"
                        *ngIf="!submenu.separator"
                        [ngClass]="{ 'p-hidden': submenu.visible === false, flex: submenu.visible }"
                        pTooltip
                        [tooltipOptions]="submenu.tooltipOptions"
                        role="none"
                    >
                        <span *ngIf="submenu.escape !== false; else htmlSubmenuLabel">{{ submenu.label }}</span>
                        <ng-template #htmlSubmenuLabel><span [innerHTML]="submenu.label | safeHtml"></span></ng-template>
                    </li>
                    <ng-template ngFor let-item [ngForOf]="submenu.items">
                        <li class="p-menu-separator" *ngIf="item.separator" [ngClass]="{ 'p-hidden': item.visible === false || submenu.visible === false }" role="separator"></li>
                        <li
                            class="p-menuitem"
                            *ngIf="!item.separator"
                            [pMenuItemContent]="item"
                            [ngClass]="{ 'p-hidden': item.visible === false || submenu.visible === false }"
                            [ngStyle]="item.style"
                            [class]="item.styleClass"
                            pTooltip
                            [tooltipOptions]="item.tooltipOptions"
                            role="none"
                        ></li>
                    </ng-template>
                </ng-template>
                <ng-template ngFor let-item [ngForOf]="model" *ngIf="!hasSubMenu()">
                    <li class="p-menu-separator" *ngIf="item.separator" [ngClass]="{ 'p-hidden': item.visible === false }" role="separator"></li>
                    <li
                        class="p-menuitem"
                        *ngIf="!item.separator"
                        [pMenuItemContent]="item"
                        [ngClass]="{ 'p-hidden': item.visible === false }"
                        [ngStyle]="item.style"
                        [class]="item.styleClass"
                        pTooltip
                        [tooltipOptions]="item.tooltipOptions"
                        role="none"
                    ></li>
                </ng-template>
            </ul>
        </div>
    `,
      animations: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-menu-overlay{position:absolute;top:0;left:0}.p-menu ul{margin:0;padding:0;list-style:none}.p-menu .p-submenu-header{align-items:center}.p-menu .p-menuitem-link{cursor:pointer;display:flex;align-items:center;text-decoration:none;overflow:hidden;position:relative}.p-menu .p-menuitem-text{line-height:1}\n"]
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i5.PrimeNGConfig
    }, {
      type: i5.OverlayService
    }];
  }, {
    model: [{
      type: Input
    }],
    popup: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input
    }],
    baseZIndex: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }]
  });
})();
class MenuModule {
  static ɵfac = function MenuModule_Factory(t) {
    return new (t || MenuModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MenuModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, RouterModule, RippleModule, TooltipModule, RouterModule, TooltipModule]
  });
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, RippleModule, TooltipModule],
      exports: [Menu, RouterModule, TooltipModule],
      declarations: [Menu, MenuItemContent, SafeHtmlPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Menu, MenuItemContent, MenuModule, SafeHtmlPipe };
