import * as i0 from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import { of } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { switchMap, catchError } from 'rxjs/operators';
class ApiService {
  constructor(http, baseApiUrl) {
    this.http = http;
    this.baseApiUrl = baseApiUrl;
  }
  setBaseApiUrl(baseApiUrl) {
    this.baseApiUrl = baseApiUrl;
  }
  get(endpoint, querystring, headers) {
    return this.http.get(this.baseApiUrl + endpoint + this.kvToQueryString(querystring), {
      headers: this.getHeaders(headers)
    });
  }
  post(endpoint, body = {}, headers, querystring) {
    return this.http.post(this.baseApiUrl + endpoint + this.kvToQueryString(querystring), body, {
      headers: this.getHeaders(headers)
    });
  }
  put(endpoint, body = {}, headers, querystring) {
    return this.http.put(this.baseApiUrl + endpoint + this.kvToQueryString(querystring), body, {
      headers: this.getHeaders(headers)
    });
  }
  patch(endpoint, body = {}, headers, querystring) {
    return this.http.patch(this.baseApiUrl + endpoint + this.kvToQueryString(querystring), body, {
      headers: this.getHeaders(headers)
    });
  }
  delete(endpoint, headers) {
    return this.http.delete(this.baseApiUrl + endpoint, {
      headers: this.getHeaders(headers)
    });
  }
  getBlob(endpoint, headers) {
    return this.http.get(this.baseApiUrl + endpoint, {
      headers: this.getHeaders(headers),
      responseType: 'blob'
    });
  }
  unAuthedGet(endpoint, querystring) {
    return this.http.get(this.baseApiUrl + endpoint + this.kvToQueryString(querystring));
  }
  unAuthedPost(endpoint, body = {}, querystring) {
    return this.http.post(this.baseApiUrl + endpoint + this.kvToQueryString(querystring), body);
  }
  getLocalAsset(url) {
    return this.http.get(url);
  }
  ifServerOnline(serverUrl) {
    return this.http.get(serverUrl).pipe(switchMap(() => of(true)), catchError(() => of(false)));
  }
  setAuthorizationHeader(headerValue) {
    this.authHeader = headerValue;
  }
  getAuthorizationHeader() {
    return this.authHeader;
  }
  kvToQueryString(kvs) {
    if (!kvs || !kvs.length) {
      return '';
    }
    return '?' + kvs.map(kv => `${kv.key}=${kv.value}`).join('&');
  }
  getHeaders(headers) {
    let base = new HttpHeaders({
      Authorization: this.authHeader || ''
    });
    if (headers) {
      try {
        headers.keys().forEach(k => {
          base = base.set(k, headers.get(k));
        });
      } catch {}
    }
    return base;
  }
  static {
    this.ɵfac = function ApiService_Factory(t) {
      return new (t || ApiService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject('baseApiUrl'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApiService,
      factory: ApiService.ɵfac,
      providedIn: 'root'
    });
  }
}
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.HttpClient
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: ['baseApiUrl']
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ApiService };
